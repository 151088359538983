import React, { useState, useEffect } from 'react';

import get from 'get-value';
import { gql, useMutation, useQuery } from '@apollo/client';

// importing Material UI core
import { Button, Grid, makeStyles } from '@material-ui/core/';

// importing internal components
import { useStore } from '../stores';
import { momentDateFormat, momentTimeFormat } from '../helpers/date-helper';
import CircularUnderLoad from '../components/loading-animation';
import { getLanguageSupport } from '../utils/helper';

import { observer } from 'mobx-react';

const useStyles = makeStyles({
  userContainer: {
    padding: '10px 0',
  },
});

const createStepEntryMutation = gql`
  mutation createStepEntry(
    $id: ID!
    $date: String!
    $step: String!
    $stepType: String!
  ) {
    createStepEntry(id: $id, date: $date, step: $step, stepType: $stepType) {
      id
      date
      source {
        firstName
        lastName
      }
      activity {
        stepIn
        stepOut
      }
    }
  }
`;

const readLastStepMutation = gql`
  query readLastStep($employeeID: ID!, $date: String!) {
    readLastStep(employeeID: $employeeID, date: $date)
  }
`;

const STEP_TYPES = {
  STEP_IN: 'stepIn',
  STEP_OUT: 'stepOut',
};

const settings = getLanguageSupport();

const StepButtons = observer(() => {
  const classes = useStyles();

  const store = useStore();
  const { employeeID } = store.employeeData;
  const refetchTimeSheets = store.refetchTimeSheets;
  const refetchStepData = store.refetchStepData;

  // STATE
  const [buttonState, setButtonState] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);

  const {
    data: lastStepData,
    refetch: lastStepDataRefetch,
    loading: lastStepLoading,
  } = useQuery(readLastStepMutation, {
    variables: {
      employeeID: employeeID,
      date: momentDateFormat(),
    },
  });

  useEffect(() => {
    if (lastStepData && !lastStepLoading) {
      setButtonState(lastStepData.readLastStep);
      setDataLoading(false);
    }
  }, [lastStepData]);

  useEffect(() => {
    lastStepDataRefetch().then(() => {
      store.triggerRefetchStep(false);
    });
  }, [refetchStepData]);

  const [createStep] = useMutation(createStepEntryMutation, {
    onCompleted: async () => {
      if (!refetchTimeSheets) {
        store.toggleRefetch(true);
      }
      await lastStepDataRefetch();
      setDataLoading(false);
    },
  });

  const handleStoreTime = async () => {
    await createStep({
      variables: {
        id: employeeID,
        date: momentDateFormat(),
        step: momentTimeFormat(),
        stepType: buttonState ? STEP_TYPES.STEP_IN : STEP_TYPES.STEP_OUT,
      },
    });
  };

  if (lastStepLoading) {
    return <CircularUnderLoad size={20} />;
  }

  return (
    <Grid item>
      <Grid
        container
        direction='row'
        alignItems='flex-start'
        className={classes.userContainer}
      >
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            onClick={handleStoreTime}
            disabled={!buttonState || dataLoading}
          >
            {get(settings, 'stepButtons.stepIn', { default: 'Step In' })}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleStoreTime}
            disabled={buttonState || dataLoading}
          >
            {get(settings, 'stepButtons.stepOut', { default: 'Step Out' })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default StepButtons;
