import moment from 'moment';
import get from 'get-value';

// Internal imports
import { DATE_FORMAT, dayRender, SINGLE_DATE_FORMAT } from './date-helper';
import { getLanguageSupport } from '../utils/helper';

const settings = getLanguageSupport();

export const DOCUMENT_REQUEST_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',
  UNKNOWN: 'Unknown',
};

export const uniqueArray = (array) => {
  var j = {};

  array.forEach(function (v) {
    j[v + '::' + typeof v] = v;
  });

  return Object.keys(j).map(function (v) {
    return j[v];
  });
};

export const calculateHoliday = (start, end, freeDays) => {
  let holidayCount = 0;

  freeDays.forEach((day) => {
    for (let m = moment(start); m.isBefore(moment(end)); m.add(1, 'days')) {
      if (m.format(DATE_FORMAT) === dayRender(day)) holidayCount += 1;
    }
  });

  return holidayCount;
};

export const calculateDays = (start, end, freeDays) => {
  let first = moment(start).clone().endOf('week');
  let last = moment(end).clone().startOf('week');
  let days = (last.diff(first, 'days') * 5) / 7;
  let wfirst = first.day() - moment(start).day();
  if (moment(start).day() == 0) --wfirst;
  let wlast = moment(end).day() - last.day();
  if (moment(end).day() == 6) --wlast;

  const holidays = calculateHoliday(start, end, freeDays);

  return wfirst + Math.floor(days) + wlast - holidays;
};

export const weekendCheckAdd = (date) => {
  if (moment(date, DATE_FORMAT).day() === 5) return 3;

  return 1;
};

export const weekendCheckSub = (date) => {
  if (moment(date, DATE_FORMAT).day() === 1) return 3;

  return 1;
};

export const weekendCheck = (date) => {
  if (moment(date, DATE_FORMAT).day() === 6) return 2;

  return 0;
};

export const checkFreeDay = (date) => {
  if (moment(date, DATE_FORMAT).day() === 5) return 3;
  return 1;
};

export const getPendingStatus = (array) => {
  const pendingCount = array.length;
  let count = 0;

  array.forEach((arrayElement) => {
    if (arrayElement.status === DOCUMENT_REQUEST_STATUS.PENDING) {
      count += 1;
    }
  });

  if (count === pendingCount && pendingCount !== 0) return false;
  return true;
};

export const FIELD_TYPES = {
  text: 'text',
  date: 'date',
  number: 'number',
  image: 'image',
  unknown: 'unknown',
};

export const SOURCE_TYPES = {
  user: 'user',
  system: 'system',
};

export const FORM_FIELDS = {
  dateTo: 'dateTo',
  dateFrom: 'dateFrom',
  numberOfDays: 'numberOfDays',
  replacementName: 'replacementName',
  documentDestination: 'documentDestination',
};

export const REQUEST_FIELDS = {
  'company.lastRegistrationNumber': '',
  dateFrom: '',
  dateTo: '',
  'employee.firstName': '',
  'employee.lastName': '',
  'employee.ssn': '',
  'employee.idCardSeries': '',
  'employee.idCardNumber': '',
  'employee.dateOfEmployment': '',
  numberOfDays: '',
  'system.currentDate': '',
  replacementName: '',
  documentDestination: '',
};

export const translateStatus = (status) => {
  switch (status) {
    case DOCUMENT_REQUEST_STATUS.PENDING:
      return get(settings, 'documents.statusTypes.pending', {
        default: DOCUMENT_REQUEST_STATUS.PENDING,
      });
    case DOCUMENT_REQUEST_STATUS.REJECTED:
      return get(settings, 'documents.statusTypes.rejected', {
        default: DOCUMENT_REQUEST_STATUS.REJECTED,
      });
    case DOCUMENT_REQUEST_STATUS.APPROVED:
      return get(settings, 'documents.statusTypes.approved', {
        default: DOCUMENT_REQUEST_STATUS.APPROVED,
      });
    case DOCUMENT_REQUEST_STATUS.CANCELED:
      return get(settings, 'documents.statusTypes.canceled', {
        default: DOCUMENT_REQUEST_STATUS.APPROVED,
      });

    default:
      return get(settings, 'documents.statusTypes.unknown', {
        default: DOCUMENT_REQUEST_STATUS.UNKNOWN,
      });
  }
};

export const getFieldType = (field) => {
  switch (field) {
    case '{employee.lastName}':
    case '{employee.firstName}':
    case '{replacementName}':
    case '{documentDestination}':
    case '{employee.ssn}':
      return FIELD_TYPES.text;

    case '{company.lastRegistrationNumber}':
    case '{numberOfDays}':
      return FIELD_TYPES.number;

    case '{system.currentDate}':
    case '{system.currentYear}':
    case '{dateFrom}':
    case '{dateTo}':
      return FIELD_TYPES.date;

    case '{employee.signature}':
      return FIELD_TYPES.image;

    default:
      return FIELD_TYPES.unknown;
  }
};

const getFieldSource = (field) => {
  let source = SOURCE_TYPES.user;
  if (field.includes('.')) {
    source = SOURCE_TYPES.system;
  }
  return source;
};

const parseHTML = (htmlContent) => {
  const regExp = /({[a-zA-Z.]+})/g;
  return htmlContent.match(regExp);
};

export const buildFields = (htmlContent) => {
  const currentArray = [];

  const fields = parseHTML(htmlContent);

  fields &&
    fields.forEach((fieldItem) => {
      currentArray.push({
        fieldName: fieldItem.replace(/{*}*/g, ''),
        fieldType: getFieldType(fieldItem),
        fieldSource: getFieldSource(fieldItem),
        fieldValue: '',
      });
    });

  return currentArray;
};

export const checkSystemFields = (field, systemFields) => {
  let isSystemField = false;

  systemFields.forEach((fieldItem) => {
    if (field === fieldItem) {
      isSystemField = true;
    }
  });

  return isSystemField;
};

export const completeSystemFields = (completedFields, requestFields) => {
  const returnArray = [];

  requestFields.forEach((requestItem) => {
    returnArray.push({
      ...requestItem,
      fieldValue: completedFields[requestItem.fieldName],
    });
  });

  returnArray.forEach((arrayItem) => delete arrayItem['__typename']);

  return returnArray;
};

export const completeEditFields = (completedFields, requestFields) => {
  const returnArray = [];

  requestFields.forEach((requestItem) => {
    if (requestItem.fieldSource === SOURCE_TYPES.user) {
      returnArray.push({
        ...requestItem,
        fieldValue: completedFields[requestItem.fieldName],
      });
    }
  });

  returnArray.forEach((arrayItem) => delete arrayItem['__typename']);

  return returnArray;
};

export const filterFields = (fields) => {
  const newArray = [];

  fields.forEach((fieldItem) => {
    switch (fieldItem.fieldName) {
      case FORM_FIELDS.dateFrom:
        newArray[0] = fieldItem;
        break;
      case FORM_FIELDS.dateTo:
        newArray[1] = fieldItem;
        break;
      case FORM_FIELDS.numberOfDays:
        newArray[2] = fieldItem;
        break;
      default:
        break;
    }
  });

  let finalArray = newArray.concat(fields);

  finalArray = [...new Set(finalArray)];

  return finalArray;
};

export const inputPropsRender = (fieldName, dateFrom, dateTo) => {
  let inputProps;
  switch (fieldName) {
    case 'numberOfDays':
      inputProps = {
        inputProps: {
          min: 1,
          max: 30,
        },
      };
      break;

    case 'dateFrom':
      inputProps = {
        inputProps: {
          min: moment().format(DATE_FORMAT),
          max: dateTo,
        },
      };
      break;

    case 'dateTo':
      inputProps = {
        inputProps: {
          min: dateFrom,
        },
      };
      break;

    default:
      break;
  }

  return inputProps;
};

export const enumerateForFreeDay = (startDate, endDate, freeDays) => {
  let hasFreeDay = 0;

  let currDate = moment(startDate).startOf('day');
  let lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    freeDays.forEach((day) => {
      if (
        dayRender(day) ===
        moment(currDate.clone().toDate()).format(SINGLE_DATE_FORMAT.DAYMONTH)
      ) {
        hasFreeDay++;
      }
    });
  }

  return hasFreeDay;
};
