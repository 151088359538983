import React from 'react';
import moment from 'moment';

import { makeStyles, Typography, TextField } from '@material-ui/core';
import { DATE_FORMAT, RO_DATE_FORMAT } from '../../../helpers/date-helper';

const useStyles = makeStyles({
  fields: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 'inherit',
  },
  secondFieldStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default function CardRow(props) {
  const {
    rowTitle,
    value,
    edit,
    errorMessage = '',
    error = false,
    name,
    onChange,
    type = 'text',
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.fields}>
      <Typography variant='body1'>{rowTitle}</Typography>
      <div className={classes.secondFieldStyle}>
        {!edit ? (
          <Typography variant='body1'>
            {type === 'date'
              ? value
                ? moment(value).format(RO_DATE_FORMAT)
                : ''
              : value}
          </Typography>
        ) : (
          <TextField
            type={type}
            value={type === 'date' ? moment(value).format(DATE_FORMAT) : value}
            name={name}
            onChange={onChange}
            helperText={errorMessage}
            error={error}
          />
        )}
      </div>
    </div>
  );
}
