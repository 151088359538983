import React from 'react';
import get from 'get-value';

// importing Material UI elements
import {
  TextField,
  IconButton,
  Grid,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// importing Material UI icons

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
  scroll: {
    padding: '0px',
    overflowY: 'scrollbar',
  },
  firstGrid: {
    padding: '0px',
    alignItems: 'space-between',
  },
  textField: {
    marginRight: '15px',
  },
  padding: {
    padding: '0px',
  },
  secondTextField: {
    width: '230px',
  },
  iconButton: {
    padding: '0px',
    marginTop: '7px',
  },
  icon: {
    padding: '0px',
    marginTop: '15px',
    color: 'black',
  },
  addIconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const UploadSection = (props) => {
  const {
    dataArray,
    setDataArray,
    settings,
    filesArray,
    setFilesArray,
  } = props;
  const classes = useStyles();

  // Requires a state with an empty array and an empty files array
  let index = 0;
  const handleChange = (event, index) => {
    const currentArray = [...dataArray];
    const currentFiles = [...filesArray];

    if (event.target.name === 'description') {
      currentArray[index - 1].description = event.target.value;
    } else {
      currentArray[index - 1].uploadURL = event.target.files[0].name;
      currentFiles.push(event.target.files[0]);
    }

    setFilesArray(currentFiles);
    setDataArray(currentArray);
  };

  const renderFields = dataArray.map(() => {
    index += 1;
    return (
      <Grid
        container
        key={index}
        spacing={2}
        direction='row'
        justify='center'
        alignItems='center'
        className={classes.scroll}
      >
        <Grid item className={classes.firstGrid}>
          <TextField
            className={classes.textField}
            id='description'
            label={get(settings, 'documents.uploadDialog.description', {
              default: 'Description',
            })}
            name='description'
            onChange={(event) => handleChange(event, index)}
          />
        </Grid>
        <Grid item className={classes.padding}>
          <TextField
            className={classes.secondTextField}
            type='file'
            id='upload'
            name='upload'
            label='Upload'
            onChange={(event) => handleChange(event, index)}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item className={classes.padding}>
          <Tooltip
            title={get(settings, 'documents.uploadDialog.removeField', {
              default: 'Remove upload field',
            })}
          >
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                if (index !== 1) {
                  handleRowDelete(index);
                }
              }}
            >
              <NotInterestedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  });

  const handleAddFields = () => {
    const currentArray = [...dataArray];

    currentArray.push({
      description: '',
      uploadURL: '',
    });

    setDataArray(currentArray);
  };

  const handleRowDelete = (index) => {
    const currentArray = [...dataArray];

    currentArray.splice(index - 1, 1);

    setDataArray(currentArray);
  };

  return (
    <>
      {renderFields}
      <>
        <Tooltip
          title={get(settings, 'documents.uploadDialog.addField', {
            default: 'Upload a new file',
          })}
        >
          <div className={classes.addIconContainer}>
            <IconButton onClick={handleAddFields} className={classes.icon}>
              <AddIcon fontSize='large' />
            </IconButton>
          </div>
        </Tooltip>
      </>
    </>
  );
};

export default UploadSection;
