//* Library imports
import React from 'react';
import get from 'get-value';

//* Material UI imports
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';

//* Custom imports
import CalendarDashboard from './calendar';
import LinearBuffer from './components/buffer';
import SecondCard from './components/card';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: 'white',
    boxShadow: '3px 5px #EEEEEE',
    marginBottom: '15px',
    color: '#2c2bfa',
    padding: '0px',
    width: '40px',
    height: '40px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    borderRadius: '20px',
  },
  cardContainer: {
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    },
  },
}));

const DashboardOverview = (props) => {
  const { settings } = props;
  const classes = useStyles();

  return (
    <div className='sectionContainer'>
      <Grid container spacing={2} className={classes.cardContainer}>
        <SecondCard
          title={get(settings, 'dashboardMenu.taskManagement', {
            default: 'Tasks Management: In Progress',
          })}
        >
          <LinearBuffer />
        </SecondCard>

        <CalendarDashboard />

        <SecondCard
          title={get(settings, 'dashboardMenu.addCard', {
            default: 'Add card',
          })}
        >
          <AddIcon className={classes.iconButton} />
        </SecondCard>
      </Grid>
    </div>
  );
};

export default DashboardOverview;
