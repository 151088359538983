import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 10px)',
    height: 'auto',
    marginLeft: '10px',
    borderRadius: '10px',
    marginTop: '8px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      width: '100%',
    },
  },
  title: {
    marginTop: '20px',
    padding: '30px',
  },
  pos: {
    marginBottom: 12,
    marginLeft: 13,
  },
}));

const CardDisplay = (props) => {
  const { title, subtitle, content, children } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card className={classes.root} elevation={2}>
        <CardContent>
          <Typography className={classes.pos} color='textSecondary'>
            {subtitle}
          </Typography>
          <Typography variant='body2' component='p'>
            {content}
          </Typography>
          {children}
          <Typography
            variant='subtitle1'
            color='inherit'
            align='center'
            className={classes.title}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default CardDisplay;
