import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import './index.css';
import App from './App';
import { envSettings } from './config/config';
import { BrowserRouter } from 'react-router-dom';

const defaultTheme = createMuiTheme();
const client = new ApolloClient({
  uri: `${envSettings.BASE_URL}/graphql`,
  cache: new InMemoryCache({ addTypename: false }),
  credentials: 'include',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={defaultTheme}>
      <ApolloProvider client={client}>
        {/** TODO:
         *      fix this https://github.com/mui-org/material-ui/issues/13394
         *      related to StrictMode
         * */}
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </ApolloProvider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
