import CryptoJS from 'crypto-js';

import { envSettings } from '../config/config';

export const encryptData = (email, password) => {
  const data = [{ savedEmail: email }, { savedPassword: password }];
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    envSettings.LOGIN_DATA_PASS
  ).toString();

  return cipherText;
};

export const decryptData = (key) => {
  if (key) {
    const bytes = CryptoJS.AES.decrypt(key, envSettings.LOGIN_DATA_PASS);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
  }

  return [];
};

export const LOGIN_ERRORS = {
  INCORRECT_CREDENTIALS: 'incorrectCredentials',
  DISABLED_ACCOUNT: 'disabledAccount',
};
