import React from 'react';
import get from 'get-value';

// importing Material UI elements
import { Typography, Grid, Chip, Tooltip, makeStyles } from '@material-ui/core';
import AutocompleteField from '../../attendance/components/autocomplete-field';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flexStart',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    margin: 0,
    paddingTop: 5,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  autocompleteContainer: {
    minWidth: '125px',
  },
}));

const DestinationsRender = (props) => {
  const { settings, destinations, adminDestination, handleChange } = props;
  const classes = useStyles();
  const admins = [];

  destinations[1]?.destinations?.forEach((destinationItem) => {
    admins.push(destinationItem);
  });

  return (
    <div className={classes.root}>
      <Typography>
        {get(settings, 'documents.formDialog.destinations', {
          default: 'Destinations',
        })}
        :
      </Typography>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='center'
        wrap='nowrap'
      >
        {admins?.length === 1 ? (
          <Grid item>
            <Tooltip
              title={get(settings, 'documents.formDialog.destinations', {
                default: 'Destinations',
              })}
            >
              <Chip label={admins[0].fullName} className={classes.chip} />
            </Tooltip>
          </Grid>
        ) : (
          admins.length !== 0 && (
            <Grid item md={6} xs={12} className={classes.autocompleteContainer}>
              <AutocompleteField
                filterData={admins || []}
                handleChange={handleChange}
                filterElements={adminDestination}
                optionElement={'fullName'}
                filterPlaceHolder={get(
                  settings,
                  'documents.formDialog.admins',
                  {
                    default: 'Admins',
                  }
                )}
              />
            </Grid>
          )
        )}
        {destinations[0]?.destinations?.map((data) => {
          return (
            <Grid item key={data.id}>
              <Tooltip
                title={get(settings, 'documents.formDialog.destinations', {
                  default: 'Destinations',
                })}
              >
                <Chip label={data.fullName} className={classes.chip} />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default DestinationsRender;
