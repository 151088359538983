import React, { useEffect, useState } from 'react';
import get from 'get-value';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';

// importing internal components
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import UploadSection from '../upload-section';
import CircularUnderLoad from '../../../../components/loading-animation';

// importing constants
import { SNACKBAR_SEVERITY } from '../../../../utils/constants';
import { SINGLE_DATE_FORMAT } from '../../../../helpers/date-helper';
import APIHelper from '../../../../helpers/route-helper';

const readUploadDataQuery = gql`
  query readUploadData($requestID: ID!) {
    readUploadData(requestID: $requestID) {
      templateID
      companyID
    }
  }
`;

const createUploadedDocuments = gql`
  mutation createUploadedDocuments(
    $documentRequestID: ID!
    $uploadDocuments: [UploadDocumentsInput]
  ) {
    createUploadedDocuments(
      documentRequestID: $documentRequestID
      uploadDocuments: $uploadDocuments
    ) {
      returnDocuments {
        documentID
        uploadURL
      }
    }
  }
`;

const UploadFiles = (props) => {
  const {
    openForm,
    settings,
    handleClose,
    documentRequestID,
    snackbar,
    refetch,
  } = props;

  const [dataArray, setDataArray] = useState([
    { description: '', uploadURL: '' },
  ]);

  const [uploadData, setUploadData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [filesArray, setFilesArray] = useState([]);

  const uploadOnServer = (data) => {
    let index = 0;

    data.createUploadedDocuments.returnDocuments.forEach((documentItem) => {
      const extension = documentItem.uploadURL.split('.');

      const documentPath = `documents/${uploadData.readUploadData.companyID}/${
        uploadData.readUploadData.templateID
      }/${moment().format(SINGLE_DATE_FORMAT.YEAR)}/${moment().format(
        SINGLE_DATE_FORMAT.MONTH
      )}/${[documentRequestID, documentItem.documentID].join('_')}.${
        extension[1]
      }`;

      const form = new FormData();
      form.append('myfile', filesArray[index], documentPath);
      APIHelper.prototype
        .fetchData(`/minio/upload`, 'POST', form)
        .then((index += 1));
    });
  };

  const handleSubmit = async () => {
    await uploadDocuments({
      variables: {
        documentRequestID: documentRequestID,
        uploadDocuments: dataArray,
      },
    });
    refetch();
  };

  const [readUploadData] = useLazyQuery(readUploadDataQuery, {
    variables: {
      requestID: documentRequestID,
    },
    onCompleted: (retrievedData) => {
      if (retrievedData && retrievedData.readUploadData) {
        setUploadData(retrievedData);
        setIsDataLoading(false);
      }
    },
  });

  useEffect(() => {
    if (documentRequestID) {
      setIsDataLoading(true);
      readUploadData();
    }

    return () => {
      setUploadData(null);
    };
  }, [documentRequestID]);

  const [uploadDocuments, {}] = useMutation(createUploadedDocuments, {
    variables: {
      documentRequestID: documentRequestID,
      uploadDocuments: dataArray,
    },
    onCompleted: async (data) => {
      uploadOnServer(data);
      snackbar(
        get(settings, 'documents.uploadDialog.successMessage', {
          default: 'Upload was successful!',
        }),
        SNACKBAR_SEVERITY.SUCCESS
      );
      handleClose();
    },
  });

  return (
    <DialogLayout
      showSubmit={true}
      formTitle={get(settings, 'documents.uploadDialog.title', {
        default: 'Upload files',
      })}
      open={openForm}
      formCancelButton={get(settings, 'documents.uploadDialog.close', {
        default: 'Exit',
      })}
      handleClose={handleClose}
      maxWidth={'md'}
      handleSubmit={handleSubmit}
      formSubmitButton={get(settings, 'documents.uploadDialog.submit', {
        default: 'Upload',
      })}
    >
      {isDataLoading ? (
        <CircularUnderLoad />
      ) : (
        <UploadSection
          documentRequestID={documentRequestID}
          dataArray={dataArray}
          setDataArray={setDataArray}
          settings={settings}
          filesArray={filesArray}
          setFilesArray={setFilesArray}
        />
      )}
    </DialogLayout>
  );
};

export default UploadFiles;
