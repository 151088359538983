//Library imports
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { gql, useQuery } from '@apollo/client';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import get from 'get-value';

// importing Material UI core
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Avatar,
  Tooltip,
} from '@material-ui/core';

// importing internal components
import TableCellContent from './table-cell-content';
import {
  DATE_FORMAT,
  calculateWorkingTime,
  minutesToHoursAndMinutes,
} from '../../../helpers/date-helper';
import CircularUnderLoad from '../../../components/loading-animation';
import AttendanceInfo from './dialogs/attendance-info';
import { useStore } from '../../../stores';
import {
  DAYS_ROMANIAN,
  SUM_SYMBOL,
  WORK_STATUS,
} from '../../../utils/constants';

// Queries
const readAllFreeDaysForYearQuery = gql`
  query readAllFreeDaysForYear($year: String!) {
    readAllFreeDaysForYear(year: $year) {
      year
      days
    }
  }
`;

const tooltipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: 'black',
        backgroundColor: 'white',
        maxHeight: 400,
        overflow: 'scroll',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  currentUserAvatarIcon: {
    marginRight: 5,
    backgroundColor: '#2196f3',
    fontSize: 12,
    width: 30,
    height: 30,
    backgroundColor: '#9AAAEE',
  },
  tableHeaderStickyCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '150px',
    position: 'sticky',
    left: 0,
    zIndex: theme.zIndex.appBar + 2,
  },
  tableBodyStickyCell: {
    display: 'flex',
    alignItems: 'center',
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '207px',
    padding: '5px',
    position: 'sticky',
    left: 0,
    zIndex: theme.zIndex.appBar + 1,
  },
  tableHeaderWorkDaysCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '50px',
  },
  tableHeaderWeekendDaysCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#fafafa',
    minWidth: '50px',
  },
  tableHeaderCellContent: {
    textAlign: 'center',
  },
  hoursCell: {
    textAlign: 'center',
    position: 'sticky',
    width: '17px',
  },
  userAvatarIcon: {
    marginRight: 5,
    backgroundColor: '#BDBDBD',
    fontSize: 12,
    width: 30,
    height: 30,
  },
  workDayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
  },
  weekendDayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#f2f2f2',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
  },
  holidayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#FFE4E1',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
  },
  overviewTableSheet: {
    width: '100%',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const AttendanceSheet = observer((props) => {
  const {
    settings,
    userName,
    currentSheetDate,
    overviewData,
    refetchData,
    loadingData,
    currentYear,
  } = props;

  const store = useStore();
  const classes = useStyles();

  // STATE
  const [state, setState] = useState({});
  const [statusLocal, setStatusLocal] = useState('');
  const [openDialogInfo, setOpenDialogInfo] = useState(false);

  const { employeeID } = store.employeeData;

  useEffect(() => {
    if (store.refetchTimeSheets) {
      refetchData();
      store.toggleRefetch(false);
    }
  }, [store.refetchTimeSheets]);

  let attendances = [];

  if (overviewData) {
    attendances = [...overviewData.readOverviewOutput];
  }

  if (attendances.length > 1) {
    const connectedIndex = attendances.findIndex(
      (attendance) => attendance.employeeID === employeeID
    );
    const connectedUser = attendances.splice(connectedIndex, 1);
    attendances.unshift(connectedUser[0]);
  }

  const { data: allDaysData, loading: loadingDays } = useQuery(
    readAllFreeDaysForYearQuery,
    {
      variables: {
        year: currentYear,
      },
    }
  );

  const handleClickCell = (attendanceLocal, id, name, day) => {
    if (day.date < Date.now()) {
      if (attendanceLocal && attendanceLocal.status === WORK_STATUS.REST) {
        return;
      }

      attendanceLocal
        ? setState({ attendanceLocal, id, name, day })
        : setState({ id, name, day });

      attendanceLocal
        ? setStatusLocal(attendanceLocal.status)
        : setStatusLocal(WORK_STATUS.UNKNOWN);

      setOpenDialogInfo(true);
    }
  };

  const handleCloseDialogInfo = () => {
    setOpenDialogInfo(false);
  };

  const totalHoursCalculator = (hours) => {
    return minutesToHoursAndMinutes(calculateWorkingTime(hours));
  };

  const getTooltipContent = (attendanceLocal, day) => {
    let cellAttendance = [];

    if (day.workingDay || day.weekend) {
      if (attendanceLocal) {
        if (attendanceLocal.hours.length !== 0) {
          let hoursCalculated = totalHoursCalculator(attendanceLocal.hours);

          attendanceLocal.hours.forEach((set) => {
            cellAttendance.push(
              <TableRow key={uuidv4()}>
                <TableCell>{set.stepIn}</TableCell>
                <TableCell>{set.stepOut}</TableCell>
              </TableRow>
            );
          });

          return (
            <>
              <p>
                {get(
                  settings,
                  'timesheetViewer.tooltipItems.workedHoursLabel',
                  {
                    default: 'Worked hours',
                  }
                ) +
                  ': ' +
                  hoursCalculated}
              </p>
              <p>
                {get(
                  settings,
                  'timesheetViewer.tooltipItems.statusLabels.status',
                  {
                    default: 'Status',
                  }
                )}
                {':' +
                  get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.atWorkLabel',
                    {
                      default: 'Present',
                    }
                  )}
              </p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesInLabel',
                        { default: 'In' }
                      )}
                    </TableCell>
                    <TableCell>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesOutLabel',
                        { default: 'Out' }
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{cellAttendance}</TableBody>
              </Table>
            </>
          );
        } else {
          if (attendanceLocal.status !== WORK_STATUS.REST) {
            return (
              <>
                <Typography variant='subtitle2'>
                  {get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.noHoursLabel',
                    {
                      default: 'No hours',
                    }
                  )}
                </Typography>
              </>
            );
          } else {
            return (
              <>
                <Typography variant='subtitle2'>
                  {get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.restLabel',
                    {
                      default: 'Rest',
                    }
                  )}
                </Typography>
              </>
            );
          }
        }
      } else return '';
    } else return '';
  };

  const year = currentSheetDate.split('-')[0];
  const month = currentSheetDate.split('-')[1];
  const daysLabel = DAYS_ROMANIAN;
  const daysInMonth = new Date(year, month, 0).getDate();

  let daysCell = [];
  let daysInfo = [];

  daysCell.push(
    <TableCell key={'id'}>
      <div className={classes.hoursCell}>{SUM_SYMBOL}</div>
    </TableCell>
  );

  if (loadingDays) {
    return <CircularUnderLoad size={40} />;
  }

  for (let i = 1; i <= daysInMonth; i++) {
    let freeDay = false;
    const dayDate = new Date(year, month - 1, i);
    const dayIndex = dayDate.getDay();
    daysCell.push(
      <TableCell
        key={i}
        className={
          dayIndex === 6 || dayIndex === 0
            ? classes.tableHeaderWeekendDaysCell
            : classes.tableHeaderWorkDaysCell
        }
      >
        <div className={classes.tableHeaderCellContent}>
          {i}
          <br />
          {daysLabel[dayIndex]}
        </div>
      </TableCell>
    );

    allDaysData?.readAllFreeDaysForYear?.days?.forEach((day) => {
      let splitDay = day.split('/');
      let currentDay = new Date(
        allDaysData.readAllFreeDaysForYear.year,
        splitDay[1],
        splitDay[0]
      );
      if (String(currentDay) === String(dayDate)) {
        freeDay = true;
      }
    });

    daysInfo.push({
      date: dayDate,
      workingDay: dayIndex === 6 || dayIndex === 0 ? false : true,
      holiday: freeDay ? true : false,
      weekend: dayIndex === 6 || dayIndex === 0 ? true : false,
    });
  }

  if (loadingData) {
    return <CircularUnderLoad />;
  }

  return (
    <MuiThemeProvider theme={tooltipTheme}>
      <>
        <TableContainer className={classes.overviewTableSheet}>
          <Table stickyHeader size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderStickyCell} />
                {daysCell}
              </TableRow>
            </TableHead>
            <TableBody>
              {attendances.map((rowData, rowIndex) => {
                const isCurrentUser = userName === rowData.employeeName;
                const userInitials = rowData.employeeName
                  .split(' ')
                  .map((section) => section[0])
                  .join('')
                  .toUpperCase();

                const hourAsNumber = Number(rowData.totalHours.split(':')[0]);

                return (
                  <React.Fragment key={rowIndex}>
                    <TableRow>
                      <TableCell
                        key={`name-${rowIndex}`}
                        className={classes.tableBodyStickyCell}
                      >
                        <Avatar
                          className={
                            isCurrentUser
                              ? classes.currentUserAvatarIcon
                              : classes.userAvatarIcon
                          }
                        >
                          {userInitials}
                        </Avatar>
                        <Typography variant='body2'>
                          {rowData.employeeName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        key={rowIndex}
                        className={classes.hoursStickyCell}
                      >
                        {hourAsNumber}h
                      </TableCell>
                      {daysInfo.map((day, dayIndex) => {
                        const attendanceLocal = rowData.attendance.find(
                          (attendanceDay) =>
                            moment(attendanceDay.date, DATE_FORMAT).isSame(
                              day.date,
                              'day'
                            )
                        );
                        return (
                          <Tooltip
                            title={getTooltipContent(attendanceLocal, day)}
                            key={dayIndex}
                            interactive
                          >
                            <TableCell
                              className={
                                day.holiday
                                  ? classes.holidayCell
                                  : day.workingDay
                                  ? classes.workDayCell
                                  : classes.weekendDayCell
                              }
                              onClick={() =>
                                handleClickCell(
                                  attendanceLocal,
                                  rowData.employeeID,
                                  rowData.employeeName,
                                  day
                                )
                              }
                            >
                              <TableCellContent
                                attendanceLocal={attendanceLocal}
                                day={day}
                              />
                            </TableCell>
                          </Tooltip>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {openDialogInfo && (
          <AttendanceInfo
            open={openDialogInfo}
            handleCloseDialogInfo={handleCloseDialogInfo}
            state={state}
            statusLocal={statusLocal}
            setStatusLocal={setStatusLocal}
            settings={settings}
            refetchData={refetchData}
          />
        )}
      </>
    </MuiThemeProvider>
  );
});

export default AttendanceSheet;
