import 'rc-time-picker/assets/index.css';

import React from 'react';

import moment from 'moment';

import TimePicker from 'rc-time-picker';

const TimePickerDate = (props) => {
  const { value, onChange, disabled, index, name } = props;

  let currentValue = null;

  if (value) {
    const [currentHour, currentMinute] = value ? value.split(':') : '';
    currentValue = moment().hour(currentHour).minute(currentMinute);
  }

  return (
    <TimePicker
      disabled={disabled}
      showSecond={false}
      value={currentValue}
      popupStyle={{ zIndex: 5000 }}
      onChange={(value) => onChange(index, value, name)}
      clearIcon={false}
      allowEmpty={false}
    />
  );
};

export default TimePickerDate;
