import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import get from 'get-value';
import moment from 'moment';

// importing Material UI elements
import { Link, makeStyles } from '@material-ui/core';

// importing Material UI icons
import { AddCircleOutlineOutlined } from '@material-ui/icons';

// importing internal components
import ListContainer from '../../../core/list-container';
import Toolbar from '../../../core/toolbar';
import ToolbarFilter from '../../../core/toolbar-filter';
import RegistryForm from '../components/dialogs/registry-form';
import withSnackbar from '../../../utils/with-snackbar';

// importing constants
import { RO_DATE_FORMAT } from '../../../helpers/date-helper';
import { PAGE_SIZE } from '../../../utils/constants';

const useStyles = makeStyles(() => {
  return {
    addButton: {
      margin: '5px',
      color: '#ffffff',
      backgroundColor: '#2F80ED',
    },
  };
});

// Queries:
const readRegistryItemsQuery = gql`
  query RegistryItems(
    $offset: Int!
    $limit: Int!
    $filter: String
    $company: ID!
  ) {
    readCompany(id: $company) {
      id
      companyName
    }
    countRegistryItems(filter: $filter, company: $company)
    readRegistryItems(
      offset: $offset
      limit: $limit
      filter: $filter
      company: $company
    ) {
      id
      company {
        id
        companyName
      }
      date
      number
      type
      allocatedBy
      description
    }
  }
`;

const Registry = (props) => {
  const { settings } = props;
  const classes = useStyles();

  const companyId = useParams().id;

  // state for form dialog
  const [formInputs, setFormInputs] = useState({});
  const [openFormDialog, setOpenFormDialog] = useState(false);

  // state for toolbar filter
  const [filterValue, setFilterValue] = useState('');
  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  // DataGrid Pagination
  const [gridPage, setGridPage] = useState(1);
  const handlePageChange = async (params) => {
    setGridPage(params.page);
  };

  const handleSubmitForm = async (message) => {
    await refetch();
    props.showSnackbar(
      get(settings, `registry.snackbar.${message}`, {
        default: 'Success',
      })
    );
  };

  const handleClickRow = (registryItem) => {
    setFormInputs(registryItem);
    setOpenFormDialog(true);
  };

  const handleAddRegistryItem = () => {
    setFormInputs({ company: data.readCompany });
    setOpenFormDialog(true);
  };

  // Query function:
  const { data, loading, refetch } = useQuery(readRegistryItemsQuery, {
    variables: {
      offset: (gridPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filterValue,
      company: companyId,
    },
  });

  // Toolbar Buttons
  const toolbarButtons = !data
    ? []
    : [
        {
          name: get(settings, 'registry.toolbar.addButton', {
            default: 'Add in-out',
          }),
          style: classes.addButton,
          icon: <AddCircleOutlineOutlined />,
          handleAction: () => handleAddRegistryItem(),
        },
      ];

  // Define DataGrid columns
  const columns = [
    {
      field: 'number',
      headerName: get(settings, `registry.labels.number`),
      width: 200,
      renderCell: (params) => (
        <Link onClick={() => handleClickRow(params.row)}>
          {params.row.number}
        </Link>
      ),
    },
    {
      field: 'date',
      headerName: get(settings, `registry.labels.date`),
      width: 150,
      valueFormatter: ({ value }) => moment(value).format(RO_DATE_FORMAT),
    },
    {
      field: 'allocatedBy',
      headerName: get(settings, `registry.labels.allocatedBy`),
      width: 250,
    },
    {
      field: 'description',
      headerName: get(settings, `registry.labels.description`),
      width: 750,
    },
    {
      field: 'type',
      headerName: 'Tip',
      width: 130,
      valueFormatter: ({ value }) =>
        get(settings, `registry.documentTypeSelectLabel.${value}`, {
          default: value,
        }),
    },
  ];

  return (
    <div className='sectionContainer'>
      <Toolbar
        backwardLink='/companies'
        title={`${get(settings, 'registry.toolbar.title', {
          default: 'Registry',
        })} - ${get(data, 'readCompany.companyName', {
          default: 'company name',
        })}`}
        buttons={toolbarButtons}
      >
        <ToolbarFilter
          settings={get(settings, 'toolbarFilter', {
            default: 'Toolbar Filter',
          })}
          handleFilterChange={handleFilterChange}
        />
      </Toolbar>

      {data && (
        <ListContainer
          items={data.readRegistryItems}
          loading={loading}
          columns={columns}
          gridPage={gridPage}
          rowCount={data.countRegistryItems}
          onPageChange={handlePageChange}
          checkboxSelection={false}
        />
      )}
      <RegistryForm
        settings={settings}
        open={openFormDialog}
        initialFormInputs={formInputs}
        handleSubmitForm={handleSubmitForm}
        setOpenDialog={setOpenFormDialog}
        handleCloseForm={() => {
          setOpenFormDialog(false);
          setFormInputs({});
        }}
      />
    </div>
  );
};

export default withSnackbar(Registry);
