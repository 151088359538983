import React, { useState } from 'react';
import get from 'get-value';

// importing material UI elements
import {
  Grid,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons/';

// importing styles
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      width: '15%',
      padding: '20px 20px',
      marginTop: '-15px',
      fontSize: '20px',
      fontWeight: '700',
      [theme.breakpoints.down('sm')]: {
        width: '60%',
      },
    },
    buttons: {
      textAlign: 'right',
    },
  };
});

const ToolbarFilter = ({ handleFilterChange, settings }) => {
  const classes = useStyles();

  const [showClearButton, setShowClearButton] = useState(false);

  const [filter, setFilter] = useState('');

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleFilterChange(e.target.value);
      setShowClearButton(true);
    }
  };

  const resetFilter = () => {
    setFilter('');
    handleFilterChange('');
    setShowClearButton(false);
  };

  const handleChange = (e) => {
    setFilter(e.target.value);
    e.target.value === '' && resetFilter();
  };

  return (
    <div className={classes.toolbar}>
      <Grid container spacing={3}>
        <Grid>
          <TextField
            label={get(settings, 'filterLabel', { default: 'Filter' })}
            type='text'
            value={filter}
            onChange={handleChange}
            onKeyDown={keyPress}
            InputProps={
              showClearButton
                ? {
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={resetFilter}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default ToolbarFilter;
