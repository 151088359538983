import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'get-value';

// importing Material UI elements
import {
  Grid,
  InputAdornment,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

// importing Material UI icons
import { Visibility, VisibilityOff } from '@material-ui/icons/';

// importing internal components
import TextField from '../../../../components/forms/text-field';
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import { getLanguageSupport } from '../../../../utils/helper';
import { checkSSN } from '../../../../helpers/companies-helper';

// importing constants
import { DATE_FORMAT } from '../../../../helpers/date-helper';
import {
  EMPLOYEE_FIELDS,
  VALID_EMAIL,
  VALID_MOBILE_NUMBER,
} from '../../../../utils/form-constants';
import { STATUS_TYPE } from '../../../../helpers/employee-helper';

// Query:
const readAllEmployeeEmailsQuery = gql`
  query {
    readAllEmployeeEmails
  }
`;

const readEmployeeQuery = gql`
  query Employee($id: ID!) {
    readEmployee(id: $id) {
      id
      firstName
      lastName
      birthDate
      mobile
      status
      positionName
      contractType
      userData {
        email
        isAdmin
      }
      employmentData {
        ssn
        identityCard {
          series
          number
        }
        vacationDays
        takenVacationDays
        dateOfEmployment
      }
    }
  }
`;

// Mutation queries:
const createEmployeeMutation = gql`
  mutation CreateEmployee(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $birthDate: ISODate
    $mobile: String
    $idCardSeries: String!
    $idCardNumber: String!
    $ssn: String!
    $vacationDays: Int!
    $takenVacationDays: Int!
    $dateOfEmployment: ISODate!
    $isAdmin: Boolean!
    $positionName: String!
    $contractType: Int!
  ) {
    createEmployee(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      birthDate: $birthDate
      mobile: $mobile
      idCardSeries: $idCardSeries
      idCardNumber: $idCardNumber
      ssn: $ssn
      vacationDays: $vacationDays
      takenVacationDays: $takenVacationDays
      dateOfEmployment: $dateOfEmployment
      isAdmin: $isAdmin
      positionName: $positionName
      contractType: $contractType
    ) {
      id
    }
  }
`;

const updateEmployeeMutation = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $birthDate: ISODate
    $mobile: String
    $idCardSeries: String
    $idCardNumber: String
    $ssn: String
    $vacationDays: Int
    $takenVacationDays: Int
    $dateOfEmployment: ISODate
    $isAdmin: Boolean
    $positionName: String
    $contractType: Int
  ) {
    updateEmployee(
      id: $id
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      mobile: $mobile
      idCardSeries: $idCardSeries
      idCardNumber: $idCardNumber
      ssn: $ssn
      vacationDays: $vacationDays
      takenVacationDays: $takenVacationDays
      dateOfEmployment: $dateOfEmployment
      isAdmin: $isAdmin
      positionName: $positionName
      contractType: $contractType
    )
  }
`;

const changeEmployeeStatusMutation = gql`
  mutation ($id: ID!, $status: String!) {
    updateEmployeeStatus(id: $id, status: $status)
  }
`;

const addEmployeesToCompanyMutation = gql`
  mutation ($companyId: ID!, $employeeIds: [ID]!) {
    addEmployeesToCompany(companyId: $companyId, employeeIds: $employeeIds)
  }
`;

const createEmployeePasswordMutation = gql`
  mutation ($id: ID!, $newPassword: String!) {
    createEmployeePassword(id: $id, newPassword: $newPassword)
  }
`;

// TODO: implement global language support
const language = getLanguageSupport();

const EMPLOYEE_STATUS = {
  enabled: false,
  disabled: true,
};

const DIALOG_TITLE = {
  ADD: get(language, 'employees.formDialog.createTitle', {
    default: 'Add employee',
  }),
  EDIT: get(language, 'employees.formDialog.editTitle', {
    default: 'Edit employee',
  }),
};

const SUBMIT_BUTTON_LABEL = {
  ADD: get(language, 'formDialog.submitButtonLabel', {
    default: 'Submit',
  }),
  EDIT: get(language, 'formDialog.modifyButtonLabel', {
    default: 'Modify',
  }),
};

const REQUIRED = get(language, 'formDialog.requiredInput', {
  default: 'Required!',
});

const INCORRECT_FORMAT = get(language, 'formDialog.incorrectFormatInput', {
  default: 'Incorrect format!',
});

const EmployeesForm = (props) => {
  const { open, companyId, handleSubmitForm, handleClose, focusedEmployee } =
    props;

  const [formInputs, setFormInputs] = useState({
    ...EMPLOYEE_FIELDS,
    vacationDays: 21,
    takenVacationDays: 0,
    dateOfEmployment: moment().format(DATE_FORMAT),
  });
  const [formInputErrors, setFormInputErrors] = useState(EMPLOYEE_FIELDS);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [createdPassword, setCreatedPassword] = useState('');
  const [showCreatedPassword, setShowCreatedPassword] = useState(false);

  const [employeeEmails, setEmployeeEmails] = useState([]);

  const [dataLoading, setDataLoading] = useState(false);

  const dialogTitle = focusedEmployee ? DIALOG_TITLE.EDIT : DIALOG_TITLE.ADD;
  const submitButtonLabel = focusedEmployee
    ? SUBMIT_BUTTON_LABEL.EDIT
    : SUBMIT_BUTTON_LABEL.ADD;

  // Clear dialog when needed
  const resetDialog = (message) => {
    setFormInputs({
      ...EMPLOYEE_FIELDS,
      vacationDays: 21,
      takenVacationDays: 0,
      dateOfEmployment: moment().format(DATE_FORMAT),
    });
    setFormInputErrors(EMPLOYEE_FIELDS);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setCreatedPassword('');

    if (message) {
      handleSubmitForm(message);
    }
    handleClose();
  };

  // Apollo Queries
  // Extract all employee emails
  const [readEmployeesEmails] = useLazyQuery(readAllEmployeeEmailsQuery, {
    onCompleted: (data) => {
      const emails = data?.readAllEmployeeEmails || [];
      setEmployeeEmails(emails);
      setDataLoading(false);
    },
  });

  // Bring data for edit employee
  const [readEmployee] = useLazyQuery(readEmployeeQuery, {
    onCompleted: (data) => {
      if (data?.readEmployee) {
        const { userData, birthDate, employmentData, ...employee } =
          data.readEmployee;

        const { isAdmin, email } = userData;

        const convertedBirthDate = birthDate
          ? moment(birthDate).format(DATE_FORMAT)
          : '';

        const {
          identityCard,
          ssn,
          vacationDays,
          takenVacationDays,
          dateOfEmployment,
        } = employmentData;

        const { series: idCardSeries, number: idCardNumber } = identityCard;

        setFormInputs({
          birthDate: convertedBirthDate,
          isAdmin,
          email,
          ssn,
          idCardSeries,
          idCardNumber,
          vacationDays,
          takenVacationDays,
          dateOfEmployment: moment(dateOfEmployment).format(DATE_FORMAT),
          ...employee,
        });
        setDataLoading(false);
      }
    },
  });

  // If focusedEmployee is valid make the call for query to bring data of the member when the dialog is open
  useEffect(() => {
    if (open) {
      setDataLoading(true);
      if (focusedEmployee) {
        readEmployee({
          variables: { id: focusedEmployee },
        });
      } else {
        readEmployeesEmails();
      }
    }
  }, [open]);

  // Apollo Mutations
  const [addEmployeeToCompany, {}] = useMutation(
    addEmployeesToCompanyMutation,
    {
      onCompleted: () => {
        resetDialog('successfullyCreatedEmployeeMessage');
      },
    }
  );

  const [updateEmployeePassword, {}] = useMutation(
    createEmployeePasswordMutation
  );

  const [createEmployee, {}] = useMutation(createEmployeeMutation, {
    onCompleted: (res) => {
      addEmployeeToCompany({
        variables: {
          companyId: companyId,
          employeeIds: res.createEmployee.id,
        },
      });
    },
  });

  const handleCreateEmployee = async () => {
    const {
      confirmPassword,
      birthDate,
      dateOfEmployment,
      vacationDays,
      takenVacationDays,
      contractType,
      ...employee
    } = formInputs;

    await createEmployee({
      variables: {
        ...employee,
        vacationDays: parseInt(vacationDays),
        takenVacationDays: parseInt(takenVacationDays),
        birthDate: moment.utc(birthDate),
        dateOfEmployment: moment.utc(dateOfEmployment),
        contractType: parseInt(contractType),
      },
    });
  };

  const [updateEmployee, {}] = useMutation(updateEmployeeMutation, {
    onCompleted: () => {
      resetDialog('successfullyUpdatedEmployeeMessage');
    },
  });

  const [updateStatus, {}] = useMutation(changeEmployeeStatusMutation, {});

  const handleUpdateEmployee = async () => {
    const {
      birthDate,
      email,
      dateOfEmployment,
      vacationDays,
      takenVacationDays,
      contractType,
      ...employee
    } = formInputs;

    await updateEmployee({
      variables: {
        ...employee,
        id: focusedEmployee,
        vacationDays: parseInt(vacationDays),
        takenVacationDays: parseInt(takenVacationDays),
        birthDate: moment.utc(birthDate),
        dateOfEmployment: moment.utc(dateOfEmployment),
        contractType: parseInt(contractType),
      },
    });
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'isAdmin') {
      setFormInputs({ ...formInputs, [name]: checked });
    } else {
      if (formInputErrors[name] !== '')
        setFormInputErrors({ ...formInputErrors, [name]: '' });

      setFormInputs({ ...formInputs, [name]: value });
    }
  };

  const toggleStatus = (event) => {
    let status = event.target.checked
      ? STATUS_TYPE.DISABLED
      : STATUS_TYPE.ENABLED;
    setFormInputs({ ...formInputs, status: status });
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;

    const validateUserEmail = (email) => {
      if (employeeEmails.includes(email)) {
        return get(
          language,
          'employees.formDialog.helperText.alreadyExistEmail',
          { default: 'User with this e-mail address already exists!' }
        );
      }

      const isValidEmail = VALID_EMAIL.test(email);
      return !isValidEmail
        ? get(
            language,
            'employees.formDialog.helperText.incorrectEmailFormat',
            { default: 'Invalid e-mail format!' }
          )
        : '';
    };

    const validateMobileNumber = (mobileNumber) => {
      const isValidMobileNumber = VALID_MOBILE_NUMBER.test(mobileNumber);
      return !isValidMobileNumber
        ? get(
            language,
            'employees.formDialog.helperText.incorrectMobileFormat',
            { default: 'Invalid mobile number format!' }
          )
        : '';
    };

    const validateUserPassword = (confirmPassword) => {
      return formInputs.password !== confirmPassword
        ? get(language, 'employees.formDialog.helperText.passwordNotMatch', {
            default: "Passwords don't match!",
          })
        : '';
    };

    let errors = {}; //create new object for errors message

    // First reset errors
    setFormInputErrors(EMPLOYEE_FIELDS);
    Object.entries(formInputs).forEach(([name, value]) => {
      // Check if user e-mail address already exist:
      if (name === 'email' && value && !focusedEmployee) {
        const isInvalid = validateUserEmail(value);
        isInvalid && (formIncomplete = true);

        errors[name] = isInvalid;
      }
      // Check if mobile format is correct:
      else if (name === 'mobile' && value) {
        const isInvalid = validateMobileNumber(value);
        isInvalid && (formIncomplete = true);

        errors[name] = isInvalid;
      }
      // Check if idCardSeries length is correct:
      else if (name === 'idCardSeries' && value) {
        if (value.length !== 2) {
          formIncomplete = true;
          errors[name] = INCORRECT_FORMAT;
        }
      }
      // Check if idCardNumber length is correct:
      else if (name === 'idCardNumber' && value) {
        if (value.length !== 6) {
          formIncomplete = true;
          errors[name] = INCORRECT_FORMAT;
        }
      }
      // Check if SSN is correct:
      else if (name === 'ssn' && value) {
        const isInvalid = checkSSN(value, language);
        if (isInvalid) {
          formIncomplete = true;
          errors[name] = isInvalid;
        }
      }
      // Check if user password match with the confirm password:
      else if (name === 'confirmPassword' && value && !focusedEmployee) {
        const isInvalid = validateUserPassword(value);
        isInvalid && (formIncomplete = true);

        errors[name] = isInvalid;
      }
      // Check if it has empty field(s) (don't check not required fields):
      else if (
        !['birthDate', 'mobile', 'isAdmin'].includes(name) &&
        value === ''
      ) {
        formIncomplete = true;
        errors[name] = REQUIRED;
      }
    });

    setFormInputErrors(errors);
    return formIncomplete;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();
    if (!formIncomplete) {
      !focusedEmployee ? handleCreateEmployee() : handleUpdateEmployee();
      if (createdPassword.length !== 0) {
        generateNewPass();
      }
      handleClose();
    }
    updateStatus({
      variables: {
        id: focusedEmployee,
        status: formInputs.status,
      },
    });
  };

  const generateNewPass = () => {
    updateEmployeePassword({
      variables: {
        id: focusedEmployee,
        newPassword: createdPassword,
      },
    });
  };

  // Clear field and close the dialog
  const handleCloseForm = (event) => {
    event.preventDefault();
    resetDialog();
  };

  const statusMessage =
    formInputs?.status === 'enabled'
      ? get(language, 'companyEmployee.disableStatus', {
          default: 'Disable employee',
        })
      : get(language, 'companyEmployee.enableStatus', {
          default: 'Enable employee',
        });

  return (
    <DialogLayout
      isLoading={dataLoading}
      formTitle={dialogTitle}
      open={open}
      formSubmitButton={submitButtonLabel}
      formCancelButton={get(language, 'formDialog.cancelButtonLabel', {
        default: 'Cancel',
      })}
      handleSubmit={handleSubmit}
      handleClose={handleCloseForm}
    >
      <Grid container spacing={2}>
        <TextField
          name='firstName'
          value={formInputs.firstName}
          label={get(language, 'employees.formDialog.fieldsLabel.firstName', {
            default: 'First name',
          })}
          error={formInputErrors.firstName}
          handleChange={handleChange}
        />
        <TextField
          name='lastName'
          value={formInputs.lastName}
          label={get(language, 'employees.formDialog.fieldsLabel.lastName', {
            default: 'Last name',
          })}
          error={formInputErrors.lastName}
          handleChange={handleChange}
        />
        <TextField
          length={12}
          name='email'
          value={formInputs.email}
          label={get(language, 'employees.formDialog.fieldsLabel.email', {
            default: 'E-mail address',
          })}
          error={formInputErrors.email}
          handleChange={handleChange}
          disabled={!!focusedEmployee}
        />
        {!focusedEmployee && (
          <>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name='password'
              value={formInputs.password}
              label={get(
                language,
                'employees.formDialog.fieldsLabel.password',
                {
                  default: 'Password',
                }
              )}
              error={formInputErrors.password}
              handleChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              name='confirmPassword'
              value={formInputs.confirmPassword}
              label={get(
                language,
                'employees.formDialog.fieldsLabel.confirmPassword',
                { default: 'Confirm password' }
              )}
              error={formInputErrors.confirmPassword}
              handleChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        <TextField
          type='date'
          name='birthDate'
          required={false}
          value={formInputs.birthDate}
          label={get(language, 'employees.formDialog.fieldsLabel.birthDate', {
            default: 'Birth date',
          })}
          error={formInputErrors.birthDate}
          handleChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name='mobile'
          required={false}
          value={formInputs.mobile}
          label={get(language, 'employees.formDialog.fieldsLabel.mobile', {
            default: 'Mobile phone',
          })}
          error={formInputErrors.mobile}
          handleChange={handleChange}
        />

        <TextField
          length={2}
          name='idCardSeries'
          required
          value={formInputs.idCardSeries}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.idCardSeries',
            {
              default: 'IdCard Series',
            }
          )}
          error={formInputErrors.idCardSeries}
          handleChange={handleChange}
        />
        <TextField
          length={4}
          name='idCardNumber'
          required
          value={formInputs.idCardNumber}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.idCardNumber',
            {
              default: 'IdCard Number',
            }
          )}
          error={formInputErrors.idCardNumber}
          handleChange={handleChange}
        />
        <TextField
          name='ssn'
          required
          value={formInputs.ssn}
          label={get(language, 'employees.formDialog.fieldsLabel.ssn', {
            default: 'SSN',
          })}
          error={formInputErrors.ssn}
          handleChange={handleChange}
        />
        <TextField
          type='number'
          length={4}
          name='vacationDays'
          required
          value={formInputs.vacationDays}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.vacationDays',
            {
              default: 'vacationDays',
            }
          )}
          error={formInputErrors.vacationDays}
          handleChange={handleChange}
          inputProps={{ min: 0, max: 100 }}
        />
        <TextField
          type='number'
          length={4}
          name='takenVacationDays'
          required
          value={formInputs.takenVacationDays}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.takenVacationDays',
            {
              default: 'takenVacationDays',
            }
          )}
          error={formInputErrors.takenVacationDays}
          handleChange={handleChange}
          inputProps={{ min: 0, max: 100 }}
        />
        <TextField
          type='date'
          length={4}
          name='dateOfEmployment'
          required
          value={formInputs.dateOfEmployment}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.dateOfEmployment',
            {
              default: 'dateOfEmployment',
            }
          )}
          error={formInputErrors.dateOfEmployment}
          handleChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          name='contractType'
          type='number'
          length={4}
          required
          value={formInputs.contractType}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.contractType',
            {
              default: 'Contract Type',
            }
          )}
          error={formInputErrors.contractType}
          handleChange={handleChange}
          inputProps={{ min: 4, max: 8 }}
        />

        <TextField
          name='positionName'
          required
          value={formInputs.positionName}
          label={get(
            language,
            'employees.formDialog.fieldsLabel.positionName',
            {
              default: 'Position name',
            }
          )}
          error={formInputErrors.positionName}
          handleChange={handleChange}
        />
        {focusedEmployee && (
          <TextField
            type={showCreatedPassword ? 'text' : 'password'}
            name='password'
            value={createdPassword}
            label={get(
              language,
              'employees.formDialog.fieldsLabel.generatePassword',
              {
                default: 'generatePass',
              }
            )}
            required={false}
            handleChange={(event) => setCreatedPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowCreatedPassword(!showCreatedPassword)}
                  >
                    {showCreatedPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        <Grid container>
          <Grid item sm={6} xs>
            <FormControlLabel
              control={
                <Switch
                  checked={formInputs.isAdmin}
                  onChange={handleChange}
                  name='isAdmin'
                  color='primary'
                />
              }
              label={get(language, 'employees.formDialog.fieldsLabel.isAdmin', {
                default: 'Admin permissions',
              })}
            />
          </Grid>
          {focusedEmployee && (
            <Grid item sm={6} xs>
              <FormControlLabel
                control={
                  <Switch
                    checked={EMPLOYEE_STATUS[formInputs.status]}
                    onChange={toggleStatus}
                    name='checkedA'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={statusMessage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogLayout>
  );
};

export default EmployeesForm;
