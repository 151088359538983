import { makeAutoObservable, runInAction } from 'mobx';

export class MainStore {
  employeeData = {};
  refetchTimeSheets = true;
  refetchStepData = false;

  constructor() {
    makeAutoObservable(this);
  }

  setEmployeeData({ employeeID, fullName, email, isAdmin }) {
    this.employeeData = { employeeID, fullName, email, isAdmin };
  }

  triggerRefetchStep(value) {
    this.refetchStepData = value;
  }

  toggleRefetch(value) {
    runInAction(() => {
      this.refetchTimeSheets = value;
    });
  }
}
