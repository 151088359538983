import React from 'react';

//* Material UI
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  fields: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const EmploymentRow = (props) => {
  const { name, value } = props;

  const classes = useStyles();

  return (
    <div className={classes.fields}>
      <Typography disabled>{name}</Typography>
      <Typography disabled>{value}</Typography>
    </div>
  );
};

export default EmploymentRow;
