import React, { useState, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import { Grid, TextField, makeStyles } from '@material-ui/core';

// internal imports
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import SelectField from '../../../../components/forms/select-field';
import { MEMBER_TYPE_CODES } from '../../../../utils/constants';
import { EMPLOYEE_TO_TEAM_FIELDS } from '../../../../utils/form-constants';
import { getLanguageSupport } from '../../../../utils/helper';
import AutocompleteField from '../../../attendance/components/autocomplete-field';

// Queries:
const readNotTeamEmployeesQuery = gql`
  query($teamId: ID!) {
    readNotTeamEmployees(teamId: $teamId) {
      id
      fullName
      userData {
        email
      }
    }
  }
`;

const readMemberTypeQuery = gql`
  query($teamId: ID!, $employeeId: ID!) {
    readTeamEmployee(teamId: $teamId, employeeId: $employeeId) {
      employee {
        _id
        fullName
      }
      memberType
    }
  }
`;

// Mutations:
const addEmployeeToTeamMutation = gql`
  mutation($teamId: ID!, $employeeId: ID!, $memberType: Int) {
    addEmployeeToTeam(
      teamId: $teamId
      employeeId: $employeeId
      memberType: $memberType
    )
  }
`;

const updateEmployeeRoleInTeamMutation = gql`
  mutation($teamId: ID!, $employeeId: ID!, $memberType: Int) {
    updateEmployeeRoleInTeam(
      teamId: $teamId
      employeeId: $employeeId
      memberType: $memberType
    )
  }
`;

// TODO: implement global language support
const language = getLanguageSupport();
const DIALOG_TITLE = {
  ADD: get(language, 'companies.formDialog.addEmployeesToTeamFormTitle', {
    default: 'Add employee to ',
  }),
  EDIT: get(
    language,
    'companies.formDialog.updateEmployeeRoleInTeamFormTitle',
    {
      default: 'Edit employee',
    }
  ),
};

const SUBMIT_BUTTON_LABEL = {
  ADD: get(language, 'companies.formDialog.addEmployeesToCompanySubmitButton', {
    default: 'Add',
  }),
  EDIT: get(
    language,
    'companies.formDialog.updateEmployeeRoleInTeamSubmitButton',
    {
      default: 'Edit',
    }
  ),
};

const MEMBER_TYPES = [
  {
    label: get(language, 'teams.team.memberTypeSelectLabel.owner', {
      default: 'owner',
    }),
    value: MEMBER_TYPE_CODES.OWNER,
  },
  {
    label: get(language, 'teams.team.memberTypeSelectLabel.backup', {
      default: 'backup',
    }),
    value: MEMBER_TYPE_CODES.BACKUP,
  },
  {
    label: get(language, 'teams.team.memberTypeSelectLabel.member', {
      default: 'member',
    }),
    value: MEMBER_TYPE_CODES.MEMBER,
  },
];

const useStyles = makeStyles({
  autocomplete: {
    marginBottom: '7px',
  },
});

const MembersForm = (props) => {
  const {
    settings,
    open,
    teamId,
    handleSubmitForm,
    handleClose,
    focusedEmployee,
  } = props;

  const [formInputs, setFormInputs] = useState(EMPLOYEE_TO_TEAM_FIELDS);
  const [formInputErrors, setFormInputErrors] = useState(
    EMPLOYEE_TO_TEAM_FIELDS
  );
  const [employeesData, setEmployeesData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const classes = useStyles();

  const dialogTitle = focusedEmployee ? DIALOG_TITLE.EDIT : DIALOG_TITLE.ADD;
  const submitButtonLabel = focusedEmployee
    ? SUBMIT_BUTTON_LABEL.EDIT
    : SUBMIT_BUTTON_LABEL.ADD;

  // Clear dialog when needed
  const resetDialog = (message) => {
    setFormInputs(EMPLOYEE_TO_TEAM_FIELDS);
    setFormInputErrors(EMPLOYEE_TO_TEAM_FIELDS);

    if (message) {
      handleSubmitForm(message);
    }
    handleClose();
  };

  // Apollo Queries
  // Extract employees which do not exist in the given team
  const [readNotTeamEmployees] = useLazyQuery(readNotTeamEmployeesQuery, {
    variables: { teamId: teamId },
    onCompleted: (data) => {
      const teamData = data?.readNotTeamEmployees || [];
      setEmployeesData(teamData);
      setDataLoading(false);
    },
  });

  // Bring data for edit employee
  const [readEmployee] = useLazyQuery(readMemberTypeQuery, {
    onCompleted: (data) => {
      if (data?.readTeamEmployee?.employee) {
        setFormInputs(data.readTeamEmployee);

        // It's not necessary to call another query when the input is disabled
        setEmployeesData([
          {
            id: data.readTeamEmployee.employee._id,
            fullName: data.readTeamEmployee.employee.fullName,
          },
        ]);
      }
      setDataLoading(false);
    },
  });

  // If focusedEmployee is valid make the call for query to bring data of the member when the dialog is open
  useEffect(() => {
    if (open) {
      setDataLoading(true);
      if (focusedEmployee) {
        // focusedEmployee can vary and that's why is sent from call
        readEmployee({
          variables: {
            employeeId: focusedEmployee,
            teamId: teamId,
          },
        });
      } else {
        readNotTeamEmployees();
      }
    }
  }, [open]);

  // Apollo Mutation
  const [addEmployeeToTeam, {}] = useMutation(addEmployeeToTeamMutation, {
    variables: {
      teamId: teamId,
      employeeId: formInputs?.employee?.id,
      memberType: formInputs.memberType,
    },
    onCompleted: async () => {
      resetDialog('successfullyAddEmployeesToTeam');
    },
  });

  const [updateEmployeeRoleInTeam, {}] = useMutation(
    updateEmployeeRoleInTeamMutation,
    {
      variables: {
        teamId: teamId,
        employeeId: formInputs?.employee?._id,
        memberType: formInputs.memberType,
      },
      onCompleted: async () => {
        resetDialog('successfullyUpdateEmployeeRoleInTeam');
      },
    }
  );

  const handleChange = (event, newValue) => {
    const name = get(event, 'target.name', { default: 'employee' });
    let value = get(event, 'target.value', { default: null }) || newValue;

    if (formInputErrors[name] !== '') {
      setFormInputErrors({ ...formInputErrors, [name]: '' });
    }
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleChangeAutocomplete = (event, newValue) => {
    newValue &&
      setFormInputs({
        ...formInputs,
        employee: { ...newValue },
      });
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;
    let errors = {}; //create new object for errors message

    // First reset errors
    setFormInputErrors(EMPLOYEE_TO_TEAM_FIELDS);

    // It's necessary to use 'forEach' or 'for' in iteration if the result is not used
    Object.entries(formInputs).forEach(([name, value]) => {
      // Check if it has empty field(s) (don't check not required fields):
      //TODO: Change membership types or find another approach for team leader value
      if (value !== 0 && !value) {
        formIncomplete = true;

        errors[name] = get(settings, 'formDialog.requiredInput', {
          default: 'Required!',
        });
      }
    });

    setFormInputErrors(errors);
    return formIncomplete;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();

    // If is not selected any focusedEmployee the form will be opened as add new employee to team
    if (!formIncomplete) {
      !focusedEmployee ? addEmployeeToTeam() : updateEmployeeRoleInTeam();
      handleClose();
    }
  };

  // Clear field and close the dialog
  const handleCloseForm = (event) => {
    event.preventDefault();
    resetDialog();
  };

  return (
    <DialogLayout
      isLoading={dataLoading}
      formTitle={dialogTitle}
      open={open}
      formSubmitButton={submitButtonLabel}
      formCancelButton={get(settings, 'formDialog.cancelButtonLabel', {
        default: 'Cancel',
      })}
      handleSubmit={handleSubmit}
      handleClose={handleCloseForm}
      disabledSubmit={!formInputs.employee}
    >
      <Grid
        container
        spacing={2}
        direction='row'
        justify='flex-start'
        alignItems='center'
      >
        <Grid item sm={6} xs={12} className={classes.autocomplete}>
          <AutocompleteField
            filterData={employeesData}
            optionElement={'fullName'}
            handleChange={handleChangeAutocomplete}
            filterElements={formInputs?.employee}
            filterPlaceHolder={get(settings, 'teams.team.autocompleteLabel', {
              default: 'Employee',
            })}
            fieldVariant='outlined'
            fieldMargin='normal'
            disabled={!!focusedEmployee}
            teamAutocomplete={true}
            focusedEmployee={focusedEmployee}
          />
        </Grid>

        <SelectField
          name={'memberType'}
          value={formInputs?.memberType}
          label={get(settings, 'teams.team.memberType', {
            default: 'Member type',
          })}
          error={formInputErrors.memberType}
          length={6}
          required={true}
          itemList={MEMBER_TYPES}
          itemNameList={['label']}
          menuItemValue='value'
          handleChange={handleChange}
        />
      </Grid>
    </DialogLayout>
  );
};

export default MembersForm;
