import React from 'react';
import get from 'get-value';
import { envSettings } from '../../../config/config';
// importing material UI elements
import {
  makeStyles,
  ListItemIcon,
  ListItem,
  Typography,
} from '@material-ui/core';

// importing Material UI icons
import {
  Edit,
  People,
  Delete,
  Assignment,
  BorderAll,
  Business,
} from '@material-ui/icons';

// importing components
import CardLayout from '../../../core/card-layout';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    marginLeft: 'auto',
  },
  iconLink: {
    textDecoration: 'none',
  },
  detailsHeader: {
    fontWeight: 'bold',
  },
}));

const CardDisplay = (props) => {
  const { company, settings, handleClickEdit, handleClickRemove } = props;
  const classes = useStyles();

  const cardActions = [
    {
      name: 'edit',
      icon: <Edit fontSize='large' />,
      onClick: handleClickEdit,
    },
    {
      name: 'members',
      icon: (
        <>
          <People fontSize='large' /> {company.totalEmployees}
        </>
      ),
      linkTo: `/companies/${company.id}`,
      iconClassName: classes.iconLink,
    },
    {
      name: 'registry',
      icon: <Assignment fontSize='large' />,
      linkTo: `/registry/${company.id}`,
    },
    {
      name: 'templates',
      icon: <BorderAll fontSize='large' />,
      linkTo: `/templates/${company.id}`,
    },
    {
      name: 'remove',
      icon: <Delete fontSize='large' />,
      onClick: handleClickRemove,
      iconClassName: classes.deleteIcon,
      disabled: company.totalEmployees !== 0,
    },
  ];

  const detailsHeader = (
    <ListItem dense>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <Typography className={classes.detailsHeader}>
        {get(settings, `companies.cardContainer.cardHeader`, {
          default: 'Card header',
        })}
      </Typography>
    </ListItem>
  );

  return (
    <CardLayout
      item={company}
      settings={settings}
      collection={'companies'}
      title={company.companyName}
      actions={cardActions}
      logoImageUrl={
        company.logo
          ? `${envSettings.BASE_URL}/minio/${company.logo}`
          : `${envSettings.BASE_URL}/minio/not-available.png`
      }
      detailsHeader={detailsHeader}
      details={[
        { name: 'companyName', value: company.companyName },
        { name: 'vatNumber', value: company.vatNumber },
        { name: 'registrationNumber', value: company.registrationNumber },
        { name: 'address', value: company.address },
      ]}
    />
  );
};

export default CardDisplay;
