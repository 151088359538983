import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

// importing internal components
import { getLanguageSupport } from '../utils/helper';
import Members from '../modules/team/views/members';
import Teams from '../modules/team/teams';
import Employees from '../modules/company/views/employees';
import Companies from '../modules/company/companies';
import Templates from '../modules/company/views/templates';
import Documents from '../modules/documents/documents';
import DashboardOverview from '../modules/dashboard/dashboard-overview';
import Login from '../modules/user/login';
import Account from '../modules/user/account';
import TemplateForm from '../modules/company/views/template-form';
import Registry from '../modules/company/views/registry';
import Attendance from '../modules/attendance/attendance';
import Settings from '../modules/settings/settings';
import RequestDetails from '../modules/documents/views/request-details';
import { useStore } from '../stores';

const settings = getLanguageSupport();

const ProtectedRoute = (props) => {
  const { isAdmin, ...restProps } = props;
  if (isAdmin) return <Route {...restProps} />;
  return <Redirect to='/' />;
};

const Routes = observer(({ userAuthenticated }) => {
  const store = useStore();
  const { isAdmin } = store.employeeData;

  if (userAuthenticated) {
    return (
      <Switch>
        <Route
          exact
          path='/'
          component={() => <DashboardOverview settings={settings} />}
        />
        <Route
          exact
          path='/attendance'
          component={() => <Attendance settings={settings} />}
        />
        <Route
          exact
          path='/documents'
          component={() => <Documents settings={settings} />}
        />
        <Route
          exact
          path='/edit'
          component={() => <EditAttendanceSheet settings={settings} />}
        />
        <Route
          exact
          path='/overview'
          component={() => <Attendance settings={settings} />}
        />
        <Route
          exact
          path='/account'
          component={() => <Account settings={settings} />}
        />
        <Route
          exact
          path='/document-request-details/:id/:location'
          component={() => <RequestDetails settings={settings} />}
        />

        <Route
          exact
          path='/teams'
          component={() => <Teams settings={settings} />}
        />
        <Route
          exact
          path='/teams/:id'
          component={() => <Members settings={settings} />}
        />

        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/companies'
          component={() => <Companies settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/companies/:id'
          component={() => <Employees settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/document-template'
          component={() => <TemplateForm settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/document-template/:id'
          component={() => <TemplateForm settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/registry/:id'
          component={() => <Registry settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/templates/:id'
          component={() => <Templates settings={settings} />}
        />
        <ProtectedRoute
          isAdmin={isAdmin}
          exact
          path='/settings'
          component={() => <Settings settings={settings} />}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path='/login' component={() => <Login />} />
      </Switch>
    );
  }
});

export default Routes;
