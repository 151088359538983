import { envSettings } from '../config/config';

export default class APIHelper {
  getConfig(method, data, stringify) {
    const config = { method, credentials: 'include' };

    if (data) {
      config.body = stringify ? JSON.stringify(data) : data;
    }
    if (stringify) {
      config.headers = { 'Content-Type': 'application/json' };
    }

    return config;
  }

  fetchData(url, method = 'GET', body = null, stringify = false) {
    return fetch(
      `${envSettings.BASE_URL}${url}`,
      this.getConfig(method, body, stringify)
    ).then((result) => result);
  }
}
