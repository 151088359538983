import roSettings from '../resources/translations/ro';
import enSettings from '../resources/translations/en';
import { envSettings } from '../config/config';

export const getLanguageSupport = () => {
  // TODO: Rethink language support
  switch (envSettings.APP_LANGUAGE) {
    case 'ro':
      return roSettings;
    case 'en':
      return enSettings;
    default:
      return {};
  }
};
