import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import { Grid, makeStyles } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

// importing components
import Toolbar from '../../core/toolbar';
import ToolbarFilter from '../../core/toolbar-filter';
import CardDisplay from './components/card-display';
import CompanyForm from './components/dialogs/company-form';
import RemoveForm from '../../components/dialogs/remove-form';
import CircularUnderLoad from '../../components/loading-animation';
import withSnackbar from '../../utils/with-snackbar';

// importing constants
import { SNACKBAR_SEVERITY } from '../../utils/constants';

const useStyles = makeStyles((theme) => {
  return {
    addButton: {
      margin: '5px',
      color: '#ffffff',
      backgroundColor: '#2F80ED',
    },
    companies: {
      padding: '10px',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
  };
});

// Queries:
const readCompaniesQuery = gql`
  query Company($filter: String) {
    readCompanies(filter: $filter) {
      id
      companyName
      vatNumber
      registrationNumber
      address
      logo
      totalEmployees
    }
  }
`;

const countEmployeesQuery = gql`
  query readCompany($id: ID!) {
    countEmployees: countCompanyEmployees(companyId: $id)
  }
`;

// Mutations:
const removeCompanyMutation = gql`
  mutation($companyId: ID!) {
    removeCompany(companyId: $companyId)
  }
`;

const Companies = (props) => {
  const { settings } = props;
  const classes = useStyles();

  // State
  const [filterValue, setFilterValue] = useState('');
  const [focusedCompany, setFocusedCompany] = useState(null);
  const [focusedItemName, setFocusedItemName] = useState('');
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Handlers
  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  const handleSubmitForm = async (
    message,
    severity = SNACKBAR_SEVERITY.SUCCESS
  ) => {
    await refetch();
    props.showSnackbar(
      get(settings, `companies.snackbar.${message}`, { default: message }),
      severity
    );
  };
  const handleClickEdit = (company) => {
    setOpenFormDialog(true);
    setFocusedCompany(company);
  };

  const handleClickRemove = (company) => {
    setOpenConfirmDialog(true);
    setFocusedCompany(company.id);
    setFocusedItemName(company.companyName);
  };

  // Query functions:
  const { data, loading, refetch } = useQuery(readCompaniesQuery, {
    variables: { filter: filterValue },
  });

  // Mutation function:
  const [removeCompany, {}] = useMutation(removeCompanyMutation, {
    onCompleted: () => {
      setOpenConfirmDialog(false);
      setFocusedCompany(null);
      setFocusedItemName('');
      handleSubmitForm('successfullyRemovedMessage');
    },
  });

  const handleRemoveCompany = async () => {
    await removeCompany({
      variables: { companyId: focusedCompany },
    });
  };

  // Stuff for toolbar
  const toolbarButtons = !data
    ? []
    : [
        {
          name: get(settings, 'companies.toolbar.addButton', {
            default: 'Add button',
          }),
          style: classes.addButton,
          icon: <AddCircleOutlineOutlined />,
          handleAction: () => setOpenFormDialog(true),
        },
      ];

  return (
    <div className='sectionContainer'>
      <Toolbar
        title={get(settings, 'companies.toolbar.title')}
        buttons={toolbarButtons}
      >
        <ToolbarFilter
          settings={get(settings, 'toolbarFilter')}
          handleFilterChange={handleFilterChange}
        />
      </Toolbar>

      {loading ? (
        <CircularUnderLoad />
      ) : (
        <Grid container spacing={2} className={classes.companies}>
          {data?.readCompanies?.map((company) => (
            <CardDisplay
              key={company.id}
              settings={settings}
              company={company}
              handleClickEdit={() => handleClickEdit(company.id)}
              handleClickRemove={() => handleClickRemove(company)}
              handleSubmitForm={handleSubmitForm}
            />
          ))}
        </Grid>
      )}

      <CompanyForm
        settings={settings}
        open={openFormDialog}
        companyID={focusedCompany}
        handleSubmitForm={handleSubmitForm}
        handleCloseForm={() => {
          setOpenFormDialog(false);
          setFocusedCompany(null);
        }}
      />

      <RemoveForm
        settings={settings}
        open={openConfirmDialog}
        handleCloseForm={() => {
          setOpenConfirmDialog(false);
          setFocusedCompany(null);
        }}
        itemID={focusedCompany}
        itemName={focusedItemName}
        collection='companies'
        handleRemove={handleRemoveCompany}
        query={countEmployeesQuery}
      />
    </div>
  );
};

export default withSnackbar(Companies);
