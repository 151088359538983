import React from 'react';
import get from 'get-value';

// importing material UI elements
import { Grid, Divider, IconButton, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';

import TextField from './text-field';

// importing styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  addButton: {
    textAlign: 'center',
  },
}));

const FormGroup = (props) => {
  const {
    name: groupName,
    value: groupValue,
    defaultValue,
    error: groupError,
    label: groupLabel,
    handleChange,
  } = props;
  const classes = useStyles();

  const handleGroupChange = (event, index) => {
    const { name: fieldName, value: fieldValue } = event.target;
    handleChange(
      {
        target: {
          name: groupName,
          value: groupValue.map((groupFields, groupIndex) => {
            return groupIndex === index
              ? { ...groupFields, [fieldName]: fieldValue }
              : groupFields;
          }),
        },
      },
      index,
      fieldName
    );
  };

  const handleAddGroup = () => {
    if (!Object.values(groupValue.slice(-1)[0]).includes('')) {
      handleChange({
        target: {
          name: groupName,
          value: [...groupValue, defaultValue[0]],
          errorValue: Array(groupValue.length + 1).fill(defaultValue[0]),
        },
      });
    }
  };

  const handleRemoveGroup = (index) => {
    if (groupValue.length > 1) {
      handleChange({
        target: {
          name: groupName,
          value: groupValue.filter(
            (_, groupIndex) => groupIndex !== parseInt(index)
          ),
          errorValue: Array(groupValue.length - 1).fill(defaultValue[0]),
        },
      });
    }
  };

  return (
    <Grid>
      <>
        <Divider className={classes.divider} />
        <Typography variant='h6' gutterBottom>
          {groupLabel.groupLabel}
        </Typography>
      </>
      {groupValue.map((groupFields, groupIndex) => {
        return (
          <Grid
            container
            key={`group-fields-${groupIndex}`}
            spacing={2}
            justify='center'
          >
            {Object.entries(groupFields).map(
              ([fieldName, fieldValue], fieldIndex) => (
                <TextField
                  key={`group-${groupIndex}-${fieldIndex}`}
                  name={fieldName}
                  value={fieldValue}
                  label={groupLabel?.fieldLabels[fieldIndex]}
                  length={Object.keys(groupFields).length % 3 === 0 ? 3 : 4}
                  error={get(groupError, `${groupIndex}.${fieldName}`, {
                    default: '',
                  })}
                  onChange={(event) =>
                    handleGroupChange(event, groupIndex, fieldName)
                  }
                />
              )
            )}

            <IconButton onClick={() => handleRemoveGroup(groupIndex)}>
              <RemoveCircle />
            </IconButton>
          </Grid>
        );
      })}
      <Grid className={classes.addButton}>
        <IconButton onClick={handleAddGroup}>
          <AddCircle />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default FormGroup;
