import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const RO_DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const RO_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm A';

export const SINGLE_DATE_FORMAT = {
  YEAR: 'YYYY',
  MONTH: 'M',
  DAYMONTH: 'DD/MM',
};

export const momentTimeFormat = (dateString) => {
  if (dateString) {
    return moment(new Date(dateString)).format(TIME_FORMAT);
  }
  return moment().format(TIME_FORMAT);
};

export const momentDateFormat = (dateTimeString) => {
  if (dateTimeString) {
    return moment(new Date(dateTimeString)).format(DATE_FORMAT);
  }
  return moment().format(DATE_FORMAT);
};

export const calculateWorkingTime = (hours) => {
  let totalWorkingMinutes = 0;
  hours.forEach((touple) => {
    if (touple.stepIn && touple.stepOut) {
      totalWorkingMinutes += moment
        .duration(
          moment(touple.stepOut, 'HH:mm').diff(moment(touple.stepIn, 'HH:mm'))
        )
        .asMinutes();
    }
  });
  return totalWorkingMinutes;
};

export const minutesToHoursAndMinutes = (minutes) => {
  var hours = Math.floor(minutes / 60);
  var minutes = Math.round(Math.floor(minutes % 60) / 10);
  return hours + '.' + minutes + 'h';
};

export const minutesToHours = (minutes) => {
  return Math.floor(minutes / 60) + 'h';
};

export const calculateMonthAndYear = (currentDate) => {
  const [currentMonth, currentYear] = currentDate.split('-');
  return `${currentMonth}-${currentYear}`;
};

export const dayRender = (day) => {
  // Function to properly calculate freeDays from backend
  const daySplit = day.split('/');
  let month = String(Number(daySplit[1]) + 1);
  if (String(month).length === 1) month = '0' + month;
  return daySplit[0] + '/' + month;
};
