//*Library imports
import React, { useState } from 'react';
import { AutoForm, ErrorField, SubmitField } from 'uniforms-material';
import { bridge as schema } from '../uniform-schema';
import get from 'get-value';
import { Button, makeStyles, FormHelperText } from '@material-ui/core';

//*Custom imports
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import withSnackbar from '../../../../utils/with-snackbar';
import PasswordField from '../password-field';

//*Style
const useStyles = makeStyles({
  fieldsStyle: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonStyles: {
    display: 'flex',
    float: 'right',
    padding: '2px',
  },
  autoForm: {
    marginTop: '0px',
    padding: '0px',
  },
});

const PasswordForm = (props) => {
  const { openForm, handleOnClose, savePasswordChanges, settings } = props;
  const [inputProps, setInputProps] = useState({});

  const classes = useStyles();

  const handleSubmit = (model) => {
    savePasswordChanges(model).then((result) => {
      if (!result.data.updateEmployeePassword) {
        setInputProps({ error: true });
      } else {
        handleOnClose();
      }
    });
  };

  return (
    <DialogLayout
      open={openForm}
      formTitle={get(settings, 'accountPage.account.passwordTitle', {
        default: 'Change Password',
      })}
      settings={settings}
      uniformForm
    >
      <AutoForm
        className={classes.autoForm}
        schema={schema}
        onSubmit={handleSubmit}
      >
        <div className={classes.fieldsStyle}>
          <PasswordField
            label={get(settings, 'accountPage.account.oldPassword', {
              default: 'oldPassword',
            })}
            name='oldPassword'
            inputProps={inputProps}
          />
          <ErrorField name='oldPassword'>
            {get(settings, 'accountPage.account.errorField', {
              default: 'errorField',
            })}
          </ErrorField>

          {inputProps.error && (
            <FormHelperText error>
              {get(settings, 'accountPage.account.formHelper', {
                default: 'formHelper',
              })}
            </FormHelperText>
          )}

          <PasswordField
            label={get(settings, 'accountPage.account.newPassword', {
              default: 'newPassword',
            })}
            name='newPassword'
            inputProps={inputProps}
          />
          <ErrorField name='newPassword'>
            {get(settings, 'accountPage.account.matchingPasswordsError', {
              default: 'matchingPasswordsError',
            })}
          </ErrorField>
          <PasswordField
            label={get(settings, 'accountPage.account.confirmNewPassword', {
              default: 'confirmNewPassword',
            })}
            name='confirmNewPassword'
            inputProps={inputProps}
          />
          <ErrorField name='confirmNewPassword'>
            {get(settings, 'accountPage.account.matchingPasswordsError', {
              default: 'matchingPasswordsError',
            })}
          </ErrorField>
        </div>
        <div className={classes.buttonStyles}>
          <Button
            name='close'
            variant='text'
            onClick={() => handleOnClose(false)}
            color='secondary'
          >
            {get(settings, 'accountPage.account.cancelButton', {
              default: 'cancelButton',
            })}
          </Button>
          <SubmitField name='submit' variant='text' color='primary'>
            {get(settings, 'accountPage.account.submitButton', {
              default: 'submitButton',
            })}
          </SubmitField>
        </div>
      </AutoForm>
    </DialogLayout>
  );
};

export default withSnackbar(PasswordForm);
