//* Library imports
import React, { useState } from 'react';
import { AutoField } from 'uniforms-material';
import { makeStyles, InputAdornment } from '@material-ui/core';
import {
  Visibility as Visibility,
  VisibilityOff as VisibilityOff,
} from '@material-ui/icons';

//*Style
const useStyles = makeStyles({
  inputAdornmentColor: {
    color: '#3f51b5',
  },
});

const PasswordField = (props) => {
  const { name, inputProps, label } = props;
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AutoField
      label={label}
      name={name}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position='end'
            onClick={handleClick}
            className={classes.inputAdornmentColor}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      {...inputProps}
    />
  );
};

export default PasswordField;
