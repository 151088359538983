import React from 'react';
import get from 'get-value';

// importing Material UI elements
import {
  TextField,
  IconButton,
  Grid,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// importing Material UI icons
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// importing internal components
import { getLanguageSupport } from '../../../utils/helper';

const settings = getLanguageSupport();

const TEXT_FIELD = get(settings, 'attendancePage.day', {
  default: { filterLabel: 'Day' },
});

const REMOVE_DAY = get(settings, 'attendancePage.removeDay', {
  default: { filterLabel: 'Remove' },
});

const ADD_DAY = get(settings, 'attendancePage.addDay', {
  default: { filterLabel: 'Add' },
});

const useStyles = makeStyles({
  scroll: {
    overflowY: 'scrollbar',
    maxHeight: 300,
  },
  firstGrid: {
    padding: '0px',
    alignItems: 'space-between',
  },
  textField: {
    marginRight: '15px',
  },
  iconButton: {
    padding: '0px',
    marginTop: '7px',
  },
  icon: {
    padding: '0px',
    marginTop: '15px',
    color: 'black',
  },
  iconsContainer: {
    textAlign: 'center',
  },
  padding: {
    padding: '0px',
  },
});

const FreeDaysMap = (props) => {
  const { freeDays, setFreeDays } = props;
  const classes = useStyles();

  let index = 0;

  const handleChange = (event, index) => {
    const currentArray = [...freeDays];

    currentArray[index - 1] = event.target.value;

    setFreeDays(currentArray);
  };

  const renderFields = freeDays?.map((dayItem) => {
    index += 1;
    return (
      <Grid
        container
        key={index}
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item className={classes.firstGrid}>
          <TextField
            className={classes.textField}
            label={TEXT_FIELD}
            value={dayItem}
            onChange={(event) => handleChange(event, index)}
          />
        </Grid>
        <Grid item className={classes.padding}>
          <Tooltip title={REMOVE_DAY}>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                handleRowDelete(index);
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  });

  const handleAddFields = () => {
    const currentArray = [...freeDays];

    currentArray.push('');

    setFreeDays(currentArray);
  };

  const handleRowDelete = (index) => {
    const currentArray = [...freeDays];

    currentArray.splice(index - 1, 1);

    setFreeDays(currentArray);
  };

  return (
    <div className={classes.scroll}>
      {renderFields}
      <div className={classes.iconsContainer}>
        <Tooltip title={ADD_DAY}>
          <IconButton onClick={handleAddFields} className={classes.icon}>
            <AddCircleIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default FreeDaysMap;
