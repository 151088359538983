import React, { useEffect, useState } from 'react';
import get from 'get-value';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { gql, useMutation } from '@apollo/client';

// importing Material UI core
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

// importing Material UI icons
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Internal imports
import { DATE_FORMAT, momentTimeFormat } from '../../../../helpers/date-helper';
import { useStore } from '../../../../stores';
import { WORK_STATUS } from '../../../../utils/constants';
import TimePickerDate from '../time-picker';

const useStyles = makeStyles((theme) => ({
  formControlDialog: {
    width: '100%',
  },
  formControlDialogAtWork: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollable: {
    maxHeight: '180px',
    overflowY: 'auto',
  },
}));

// GraphQL Queries and Operations
const updateTimesheetActivityMutation = gql`
  mutation updateTimesheetActivity(
    $id: ID!
    $date: String!
    $activity: [TimesheetItemInput]
  ) {
    updateTimesheetActivity(id: $id, date: $date, activity: $activity)
  }
`;

const removeTimesheetActivityMutation = gql`
  mutation removeTimesheetActivity($id: ID!, $date: String!) {
    removeTimesheetActivity(id: $id, date: $date)
  }
`;

const AttendanceInfo = (props) => {
  const {
    open,
    state,
    settings,
    handleCloseDialogInfo,
    statusLocal,
    setStatusLocal,
    refetchData,
  } = props;
  const classes = useStyles();
  const store = useStore();
  const { employeeID } = store.employeeData;
  const refetchTimeSheets = store.refetchTimeSheets;

  // STATE
  const [editAttendace, setEditAttendace] = useState(false);
  const [localState, setLocalState] = useState(state);

  useEffect(() => {
    return () => !refetchTimeSheets && store.toggleRefetch(true);
  }, []);

  const handleChangeStatus = (event) => {
    setStatusLocal(event.target.value);
  };

  // GraphQL Mutations calls
  const [updateTimesheet] = useMutation(updateTimesheetActivityMutation, {
    onCompleted: async () => {
      await refetchData();
    },
  });

  const [removeTimesheet] = useMutation(removeTimesheetActivityMutation, {
    onCompleted: async () => {
      await refetchData();
    },
  });
  const handleDeleteRowClick = (index) => {
    const currentHours = [...localState.attendanceLocal.hours];

    currentHours.splice(index, 1);

    setLocalState({
      ...localState,
      attendanceLocal: { ...localState.attendanceLocal, hours: currentHours },
    });
  };

  const handleStepChange = (index, value, name) => {
    const currentHours = [...localState.attendanceLocal.hours];

    currentHours[index] = {
      ...currentHours[index],
      [name]: value?.format('HH:mm'),
    };

    setLocalState({
      ...localState,
      attendanceLocal: { ...localState.attendanceLocal, hours: currentHours },
    });
  };

  const handleClickEdit = () => {
    setEditAttendace(true);
  };

  const handleClickCancelEdit = () => {
    setLocalState(state);
    setEditAttendace(false);
  };

  const handleClickSave = async () => {
    const momentFormat = moment(localState.day.date).format(DATE_FORMAT);

    let activityArray = [];

    if (statusLocal === WORK_STATUS.WORK) {
      if (localState?.attendanceLocal?.hours)
        activityArray = [...localState.attendanceLocal.hours];

      await updateTimesheet({
        variables: {
          id: state.id,
          date: momentFormat,
          activity: activityArray,
        },
      });
    } else {
      await removeTimesheet({
        variables: {
          id: state.id,
          date: momentFormat,
        },
      });
    }

    const today = moment().format(DATE_FORMAT);
    if (today == momentFormat) {
      store.triggerRefetchStep(true);
    }

    handleCloseDialogInfo();
  };

  const handleAddRow = () => {
    let currentHours = localState.attendanceLocal
      ? [...localState.attendanceLocal.hours]
      : [];

    const currentMoment = momentTimeFormat();

    currentHours.push({ stepIn: currentMoment, stepOut: currentMoment });

    setLocalState({
      ...localState,
      attendanceLocal: { ...localState.attendanceLocal, hours: currentHours },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialogInfo}>
        <DialogTitle disableTypography>
          <h2>
            {get(settings, 'timesheetViewer.dialogItems.dialogTitle', {
              default: 'Daily records',
            })}
          </h2>
          {moment(localState.day.date).format('DD/MM/YYYY')}
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseDialogInfo}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <>
            <TextField
              disabled
              value={localState.name}
              label={get(
                settings,
                'timesheetViewer.dialogItems.textFieldEmployeeLabel',
                {
                  default: 'Employee',
                }
              )}
              variant='filled'
              className={
                statusLocal === WORK_STATUS.WORK
                  ? classes.formControlDialogAtWork
                  : classes.formControlDialog
              }
            />
            <br />
            <br />
            <FormControl
              variant='filled'
              className={
                statusLocal === WORK_STATUS.WORK
                  ? classes.formControlDialogAtWork
                  : classes.formControlDialog
              }
            >
              <InputLabel>
                {get(
                  settings,
                  'timesheetViewer.tooltipItems.statusLabels.status',
                  {
                    default: 'Status',
                  }
                )}
              </InputLabel>
              <Select
                value={statusLocal}
                onChange={handleChangeStatus}
                disabled={!editAttendace}
              >
                <MenuItem value={WORK_STATUS.WORK}>
                  {get(
                    settings,
                    'timesheetViewer.dialogItems.menuItemLabels.atWorkLabel',
                    {
                      default: 'Present',
                    }
                  )}
                </MenuItem>
                <MenuItem value={WORK_STATUS.UNKNOWN}>
                  {get(
                    settings,
                    'timesheetViewer.dialogItems.menuItemLabels.unknownLabel',
                    {
                      default: 'Unknown',
                    }
                  )}
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            {statusLocal === WORK_STATUS.WORK && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesInLabel',
                        { default: 'In' }
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesOutLabel',
                        { default: 'Out' }
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesActionsLabel',
                        { default: 'Actions' }
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            )}
            <div className={classes.scrollable}>
              {statusLocal === WORK_STATUS.WORK && (
                <>
                  <Table>
                    <TableBody>
                      {localState.attendanceLocal &&
                        localState.attendanceLocal.hours &&
                        localState.attendanceLocal.hours.map((set, index) => {
                          if (set.stepIn) {
                            return (
                              <TableRow key={uuidv4()}>
                                <>
                                  <TableCell>
                                    <TimePickerDate
                                      name='stepIn'
                                      index={index}
                                      disabled={!editAttendace}
                                      value={set.stepIn}
                                      onChange={handleStepChange}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TimePickerDate
                                      name='stepOut'
                                      index={index}
                                      disabled={!editAttendace}
                                      value={set.stepOut}
                                      onChange={handleStepChange}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      disabled={!editAttendace}
                                      onClick={
                                        editAttendace
                                          ? () => handleDeleteRowClick(index)
                                          : undefined
                                      }
                                    >
                                      <DeleteIcon fontSize='small' />
                                    </IconButton>
                                  </TableCell>
                                </>
                              </TableRow>
                            );
                          }
                        })}
                    </TableBody>
                  </Table>
                </>
              )}
            </div>

            <div className={classes.addButton}>
              <IconButton
                className={classes.addButton}
                onClick={handleAddRow}
                disabled={!editAttendace}
              >
                <AddCircleIcon fontSize='small' />
              </IconButton>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          {!editAttendace ? (
            <>
              <br />
              <Button
                onClick={handleClickEdit}
                variant='text'
                disabled={localState.id !== employeeID}
              >
                {get(settings, 'timesheetViewer.dialogItems.editButtonLabel', {
                  default: 'Edit',
                })}
              </Button>
            </>
          ) : (
            <>
              <br />

              <Button variant='text' onClick={handleClickCancelEdit}>
                {get(
                  settings,
                  'timesheetViewer.dialogItems.cancelButtonLabel',
                  {
                    default: 'Cancel',
                  }
                )}
              </Button>
              <Button variant='text' onClick={handleClickSave}>
                {get(settings, 'timesheetViewer.dialogItems.saveButtonLabel', {
                  default: 'Save',
                })}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttendanceInfo;
