import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing Material UI elements
import {
  Typography,
  List,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';

// importing Material UI icons
import { Warning, Check } from '@material-ui/icons';

// importing internal components
import ConfirmForm from '../../../../components/dialogs/confirm-form';
import CircularUnderLoad from '../../../../components/loading-animation';
import { getLanguageSupport } from '../../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));

// Queries:
const readEmployeesCanBeDeletedQuery = gql`
  query readEmployeesCanBeDeleted($ids: [ID]) {
    readEmployeesCanBeDeleted(ids: $ids) {
      hasTeam
      hasTimesheets
      hasDocReq
      teams
      canBeDeleted
    }
  }
`;

const settings = getLanguageSupport();

const CONFIRM_DIALOG_TITLE = `${get(
  settings,
  'confirmDialog.dialogTitleRemove',
  {
    default: '',
  }
)} ${get(settings, 'companies.company.confirmDialog.title', {
  default: 'employees from company',
})}`;

const CONFIRM_DIALOG_MESSAGE_LABEL = get(
  settings,
  'companies.company.confirmDialog.confirmDialogMessage'
);

const NOT_DELETABLE_ITEMS_LABEL = get(
  settings,
  'companies.company.confirmDialog.notDeletableItemsHeader'
);

const MEMBERS_OF_TEAMS_LABEL = get(
  settings,
  'companies.company.confirmDialog.membersOfTeams'
);

const EXTRA_LABEL = get(settings, 'companies.company.confirmDialog.extra');

const HAS_TIMESHEETS_LABEL = get(
  settings,
  'companies.company.confirmDialog.hasTimesheets'
);

const HAS_DOC_REQ_LABEL = get(
  settings,
  'companies.company.confirmDialog.hasDocReq'
);

const RemoveEmployeesForm = (props) => {
  const {
    settings,
    open,
    handleClose,
    handleSubmit,
    selectionNames,
    selection,
  } = props;
  const classes = useStyles();

  // state for confirm-dialog
  const [canBeDeleted, setCanBeDeleted] = useState(undefined);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setIsDataLoading(true);
      readCanBeDeleted({
        variables: {
          ids: selection,
        },
      });
    } else {
      setCanBeDeleted(undefined);
    }
  }, [open]);

  // Mutation functions:
  const [readCanBeDeleted] = useLazyQuery(readEmployeesCanBeDeletedQuery, {
    onCompleted: (res) => {
      setCanBeDeleted(res.readEmployeesCanBeDeleted);
      setIsDataLoading(false);
    },
  });

  return (
    <ConfirmForm
      settings={settings}
      title={CONFIRM_DIALOG_TITLE}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      disableSubmit={!canBeDeleted?.canBeDeleted}
    >
      {isDataLoading ? (
        <CircularUnderLoad />
      ) : (
        <>
          <Typography className={classes.confirmDialogInfo} variant='subtitle1'>
            {canBeDeleted?.canBeDeleted
              ? CONFIRM_DIALOG_MESSAGE_LABEL
              : NOT_DELETABLE_ITEMS_LABEL}
          </Typography>
          <List>
            {canBeDeleted?.canBeDeleted ? (
              selectionNames.map((employee, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar className={classes.success}>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={employee.fullName} />
                </ListItem>
              ))
            ) : (
              <>
                {canBeDeleted?.hasTeam && (
                  <Typography
                    className={classes.confirmDialogInfo}
                    variant='subtitle2'
                  >
                    {MEMBERS_OF_TEAMS_LABEL}
                  </Typography>
                )}
                {canBeDeleted?.teams?.map((teamName, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.warning}>
                        <Warning />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={teamName} />
                  </ListItem>
                ))}
                {(canBeDeleted?.hasTimesheets || canBeDeleted?.hasDocReq) && (
                  <Typography
                    className={classes.confirmDialogInfo}
                    variant='subtitle2'
                  >
                    {EXTRA_LABEL}
                  </Typography>
                )}
                {canBeDeleted?.hasTimesheets && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.warning}>
                        <Warning />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={HAS_TIMESHEETS_LABEL} />
                  </ListItem>
                )}
                {canBeDeleted?.hasDocReq && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.warning}>
                        <Warning />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={HAS_DOC_REQ_LABEL} />
                  </ListItem>
                )}
              </>
            )}
          </List>
        </>
      )}
    </ConfirmForm>
  );
};

export default RemoveEmployeesForm;
