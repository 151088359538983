import React from 'react';

// importing internal components
import {
  calculateWorkingTime,
  minutesToHoursAndMinutes,
} from '../../../helpers/date-helper';
import { WORK_STATUS } from '../../../utils/constants';

const TABLE_BODY_CELL_COLORS = {
  noInfoDay: '#FFF5F6',
  workedDay: '#F0F8FF',
  freeDay: '#fafafa',
  futureDay: 'white',
  leaveDay: '#EBF8ED',
};

const TableCellContent = (props) => {
  const { attendanceLocal, day } = props;
  let cellStyle = { textAlign: 'center' };
  let cellContent = '';

  switch (true) {
    case !attendanceLocal && day.date < Date.now() && day.workingDay:
      cellStyle.backgroundColor = TABLE_BODY_CELL_COLORS.noInfoDay;
      break;
    case !day.workingDay:
      cellStyle.backgroundColor = TABLE_BODY_CELL_COLORS.freeDay;
      break;
    case day.date > Date.now() && !attendanceLocal:
      cellStyle.backgroundColor = TABLE_BODY_CELL_COLORS.futureDay;
      break;
    case day.date > Date.now() &&
      attendanceLocal &&
      attendanceLocal.status === WORK_STATUS.REST:
      cellContent = WORK_STATUS.REST_RO;
      break;
    case attendanceLocal.status === WORK_STATUS.REST && day.workingDay:
      cellContent = WORK_STATUS.REST_RO;
      break;
    case day.weekend && attendanceLocal?.status === WORK_STATUS.WORK:
    case attendanceLocal.status === WORK_STATUS.WORK:
      cellContent = minutesToHoursAndMinutes(
        calculateWorkingTime(attendanceLocal.hours)
      );
      break;
    default:
      break;
  }

  return <div style={cellStyle}>{cellContent}</div>;
};

export default TableCellContent;
