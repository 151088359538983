import React, { useState, useRef, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import JoditEditor from 'jodit-react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'get-value';

// importing Material UI elements
import {
  Chip,
  Grid,
  Button,
  Checkbox,
  Container,
  makeStyles,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from '@material-ui/core';

// importing Material UI icons
import {
  Face,
  DateRange,
  CalendarToday,
  Assignment,
  Create,
  Send,
} from '@material-ui/icons';

// importing internal components
import Toolbar from '../../../core/toolbar';
import withSnackbar from '../../../utils/with-snackbar';
import CircularUnderLoad from '../../../components/loading-animation';
import TextField from '../../../components/forms/text-field';
import { buildFields } from '../../../helpers/document-helper';

// importing constants
import { SNACKBAR_SEVERITY } from '../../../utils/constants';
import { DOCUMENT_TEMPLATE_FIELDS } from '../../../utils/form-constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
    marginBottom: 20,
    textAlign: 'center',
  },
  legend: {
    width: '100%',
    margin: '20px 0',
  },
  chipStyle: {
    margin: '0 5px',
  },
  gridContainer: {
    display: 'flex',
  },
  documentName: {
    alignSelf: 'flex-end',
    paddingTop: 3,
  },
  footerForm: {
    textAlign: 'center',
    marginTop: 80,
  },
  helperTextEditor: {
    color: '#f44336',
  },
  editorError: {
    border: '1px solid #f44336',
    width: 'calc(100% + 24px)',
  },
  editor: {
    width: 'calc(100% + 24px)',
  },
}));

// Queries
const readDocumentTemplateQuery = gql`
  query DocumentTemplate($documentID: ID!) {
    readDocumentTemplate(documentID: $documentID) {
      id
      name
      company {
        id
        companyName
      }
      verificationNeeded
      templateHTML
    }
  }
`;

const readCompanyNameQuery = gql`
  query readCompany($id: ID!) {
    readCompany(id: $id) {
      companyName
    }
  }
`;

// Mutations

const addDocumentTemplateMutation = gql`
  mutation createDocumentTemplate(
    $name: String!
    $verificationNeeded: Boolean
    $templateHTML: String!
    $company: ID!
    $fields: [FieldInput]
  ) {
    createDocumentTemplate(
      name: $name
      verificationNeeded: $verificationNeeded
      templateHTML: $templateHTML
      company: $company
      fields: $fields
    ) {
      id
      name
      templateHTML
      verificationNeeded
      company {
        companyName
      }
    }
  }
`;

const updateDocumentTemplateMutation = gql`
  mutation updateDocumentTemplate(
    $id: ID!
    $name: String!
    $verificationNeeded: Boolean
    $templateHTML: String!
    $company: ID!
    $fields: [FieldInput]
  ) {
    updateDocumentTemplate(
      id: $id
      name: $name
      verificationNeeded: $verificationNeeded
      templateHTML: $templateHTML
      company: $company
      fields: $fields
    )
  }
`;

const TemplateForm = (props) => {
  const classes = useStyles();
  const { settings } = props;

  const history = useHistory();
  const templateID = useParams().id;
  const isCreatePage = !templateID;

  const createdDocumentTemplate = localStorage.getItem(
    'createdDocumentTemplate'
  );
  localStorage.removeItem('createdDocumentTemplate');

  useEffect(() => {
    const companyID = localStorage.getItem('companyId');
    localStorage.removeItem('companyId');
    !isCreatePage &&
      readDocumentTemplate({ variables: { documentID: templateID } });

    if (companyID) {
      readCompanyName({
        variables: {
          id: companyID,
        },
      });
      setInputParams({ ...inputParams, company: companyID });
    }

    createdDocumentTemplate &&
      props.showSnackbar(
        get(
          settings,
          'documentTemplate.snackbar.successfullyCreatedDocumentTemplate',
          { default: 'Success!' }
        ),
        SNACKBAR_SEVERITY.SUCCESS
      );
  }, []);

  // Chip information
  const chipsInfo = {
    'employee.lastName': get(settings, 'documentTemplate.chipsInfo.lastName', {
      default: 'Employee last name',
    }),
    'employee.firstName': get(
      settings,
      'documentTemplate.chipsInfo.firstName',
      {
        default: 'Employee first name',
      }
    ),
    'employee.signature': get(
      settings,
      'documentTemplate.chipsInfo.signature',
      {
        default: 'Employee signature',
      }
    ),
    'employee.ssn': get(settings, 'documentTemplate.chipsInfo.ssn', {
      default: 'Employee ssn',
    }),
    'employee.idCardSeries': get(
      settings,
      'documentTemplate.chipsInfo.idCardSeries',
      {
        default: 'Employee ssn',
      }
    ),
    'employee.idCardNumber': get(
      settings,
      'documentTemplate.chipsInfo.idCardNumber',
      {
        default: 'Employee ssn',
      }
    ),
    'employee.dateOfEmployment': get(
      settings,
      'documentTemplate.chipsInfo.dateOfEmployment',
      {
        default: 'Employee date of employment ',
      }
    ),
    'employee.contractType': get(
      settings,
      'documentTemplate.chipsInfo.contractType',
      {
        default: 'Contract type of employee ',
      }
    ),
    'employee.positionName': get(
      settings,
      'documentTemplate.chipsInfo.positionName',
      {
        default: 'Position name of employee ',
      }
    ),
    'system.currentDate': get(
      settings,
      'documentTemplate.chipsInfo.currentDate',
      {
        default: 'Current date',
      }
    ),
    'system.currentYear': get(
      settings,
      'documentTemplate.chipsInfo.currentYear',
      {
        default: 'Current year',
      }
    ),

    'company.lastRegistrationNumber': get(
      settings,
      'documentTemplate.chipsInfo.lastRegistrationNumber',
      {
        default: 'Registry number',
      }
    ),

    dateFrom: get(settings, 'documentTemplate.chipsInfo.dateFrom', {
      default: 'Date from',
    }),
    dateTo: get(settings, 'documentTemplate.chipsInfo.dateTo', {
      default: 'Date to',
    }),
    numberOfDays: get(settings, 'documentTemplate.chipsInfo.numberOfDays', {
      default: 'Days',
    }),
    documentDestination: get(
      settings,
      'documentTemplate.chipsInfo.documentDestination',
      {
        default: 'Document destination',
      }
    ),
    replacementName: get(
      settings,
      'documentTemplate.chipsInfo.replacementName',
      {
        default: 'Replacement Name',
      }
    ),
  };

  const getAvatar = (key) => {
    switch (key) {
      case chipsInfo['employee.lastName']:
      case chipsInfo['employee.firstName']:
      case chipsInfo['employee.ssn']:
      case chipsInfo['employee.idCardSeries']:
      case chipsInfo['employee.idCardNumber']:
      case chipsInfo['employee.positionName']:
      case chipsInfo.replacementName:
        return <Face />;
      case chipsInfo.dateFrom:
      case chipsInfo.dateTo:
      case chipsInfo.numberOfDays:
      case chipsInfo['employee.dateOfEmployment']:
        return <DateRange />;
      case chipsInfo['system.currentDate']:
      case chipsInfo['system.currentYear']:
        return <CalendarToday />;
      case chipsInfo['company.lastRegistrationNumber']:
      case chipsInfo['employee.contractType']:
        return <Assignment />;
      case chipsInfo['employee.signature']:
        return <Create />;
      case chipsInfo.documentDestination:
        return <Send />;

      default:
        return null;
    }
  };

  // Mutations:
  const [addDocumentTemplate, {}] = useMutation(addDocumentTemplateMutation, {
    onCompleted: (res) => {
      localStorage.setItem('createdDocumentTemplate', true);
      history.push(`/document-template/${res.createDocumentTemplate.id}`);
    },
  });

  const [updateDocumentTemplate, {}] = useMutation(
    updateDocumentTemplateMutation
  );

  //inputs state
  const [inputParams, setInputParams] = useState(DOCUMENT_TEMPLATE_FIELDS);
  const [inputErrors, setInputErrors] = useState({
    name: '',
    company: '',
    editor: '',
  });

  // state for the editor
  let editorRef;
  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState('');

  // update Document template
  const [readDocumentTemplate, { loading: DocumentTemplateLoading }] =
    useLazyQuery(readDocumentTemplateQuery, {
      onCompleted: (result) => {
        setInputParams({
          name: result.readDocumentTemplate.name,
          company: result.readDocumentTemplate.company.id,
          companyName: result.readDocumentTemplate.company.companyName,
          verificationNeeded: result.readDocumentTemplate.verificationNeeded,
        });
        editor.current.value = result.readDocumentTemplate.templateHTML;
      },
    });

  const [readCompanyName] = useLazyQuery(readCompanyNameQuery, {
    onCompleted: (result) => {
      setInputParams({
        ...inputParams,
        companyName: result.readCompany.companyName,
      });
    },
  });

  // add or update
  const handleSubmit = () => {
    const formIncomplete = checkFormIncomplete();
    if (!formIncomplete) {
      if (isCreatePage) {
        handleCreateDocTemplate();
      } else {
        handleUpdateDocTemplate();
      }
    }
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;

    setInputErrors(DOCUMENT_TEMPLATE_FIELDS);
    Object.entries(inputParams).map(([name, value]) => {
      if (!['verificationNeeded', 'url'].includes(name) && !value) {
        formIncomplete = true;
        setInputErrors((prevFormInputErrors) => {
          return {
            ...prevFormInputErrors,
            [name]: get(settings, 'formDialog.requiredInput', {
              default: 'Required!',
            }),
          };
        });
      }
    });
    if (editor.current.value === '') {
      setInputErrors((prevFormInputErrors) => {
        return {
          ...prevFormInputErrors,
          editor: get(settings, 'formDialog.requiredInput', {
            default: 'Required!',
          }),
        };
      });
      formIncomplete = true;
    }

    return formIncomplete;
  };

  const handleCreateDocTemplate = () => {
    const templateFields = buildFields(editor.current.value);

    addDocumentTemplate({
      variables: {
        name: inputParams.name,
        verificationNeeded: inputParams.verificationNeeded,
        templateHTML: editor.current.value,
        company: inputParams.company,
        fields: templateFields,
      },
    });
  };

  const handleUpdateDocTemplate = () => {
    const templateFields = buildFields(editor.current.value);

    updateDocumentTemplate({
      variables: {
        id: templateID,
        name: inputParams.name,
        verificationNeeded: inputParams.verificationNeeded,
        templateHTML: editor.current.value,
        company: inputParams.company,
        fields: templateFields,
      },
    }).then((result) => {
      const { errors } = result;
      if (errors) {
        props.showSnackbar(
          get(settings, 'access.serverError', { default: 'Error!' }),
          SNACKBAR_SEVERITY.ERROR
        );
      } else {
        props.showSnackbar(
          get(
            settings,
            'documentTemplate.snackbar.successfullyUpdatedDocumentTemplate',
            { default: 'Success!' }
          ),
          SNACKBAR_SEVERITY.SUCCESS
        );
      }
    });
  };

  // editor config
  const config = {
    readonly: false,
    minHeight: 300,
    placeholder: '',
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_only_text',
    toolbarInlineForSelection: false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    disablePlugins: 'about,copy-format,clipboard',
    showSelectAll: false,
  };

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'verificationNeeded') {
      setInputParams({ ...inputParams, [name]: checked });
    } else {
      inputErrors[name] !== '' &&
        setInputErrors({ ...inputErrors, [name]: '' });
      setInputParams({ ...inputParams, [name]: value });
    }
  };

  const onBlurEditor = (event) => {
    event.preventDefault();
    event.stopPropagation();
    editor.current.value;
  };

  const onEditorChange = (editorNewValue) => {
    inputErrors.editor !== '' && setInputErrors({ ...inputErrors, editor: '' });
    editor.current.value = editorNewValue;
  };

  const handleChipClick = (chip) => {
    let textContainer = document.createElement('span');
    textContainer.innerHTML = ` {${chip}} `;
    editorRef.selection.insertNode(textContainer);
  };

  if (DocumentTemplateLoading)
    return (
      <div className='sectionContainer'>
        <Toolbar
          title={get(settings, 'addDocumentTemplate.toolbar.title', {
            default: 'Document template',
          })}
          buttons={[]}
          backwardLink={`/templates}`}
        />
        <CircularUnderLoad />
      </div>
    );

  return (
    <div className='sectionContainer'>
      <Toolbar
        title={get(settings, 'addDocumentTemplate.toolbar.title', {
          default: 'Document template',
        })}
        buttons={[]}
        backwardLink={`/templates/${inputParams.company}`}
      />
      <Container maxWidth='lg'>
        <Grid container spacing={3} className={classes.gridContainer}>
          <TextField
            className={classes.documentName}
            value={inputParams.name}
            handleChange={handleInputChange}
            variant='standard'
            size='small'
            error={inputErrors.name}
            name='name'
            label={get(settings, 'documentTemplate.fieldsLabel.documentName', {
              default: 'Document name',
            })}
          />
          <TextField
            className={classes.formControl}
            value={inputParams.companyName}
            label={get(settings, 'documentTemplate.fieldsLabel.company', {
              default: 'Company',
            })}
            variant='standard'
            name='company'
            size='small'
            disabled
          />
        </Grid>
        <FormControl
          error={!!inputErrors.editor}
          className={inputErrors.editor ? classes.editorError : classes.editor}
        >
          <JoditEditor
            id='idEditor'
            editorRef={(ref) => {
              editorRef = ref;
            }}
            ref={editor}
            name='content'
            value={editorContent}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={onBlurEditor}
            onChange={onEditorChange}
            errorMessages='true'
          />
          <div className={classes.legend}>
            {Object.keys(chipsInfo).map((chip) => (
              <Chip
                key={chip}
                className={classes.chipStyle}
                avatar={getAvatar(chipsInfo[chip])}
                clickable
                variant='outlined'
                label={chipsInfo[chip]}
                onClick={() => handleChipClick(chip)}
              />
            ))}
          </div>
        </FormControl>
        {inputErrors.editor && (
          <FormHelperText className={classes.helperTextEditor}>
            {inputErrors.editor}
          </FormHelperText>
        )}
        <div className={classes.footerForm}>
          <FormControlLabel
            control={
              <Checkbox
                name='verificationNeeded'
                checked={inputParams.verificationNeeded}
                onChange={handleInputChange}
                color='primary'
                size='small'
              />
            }
            label={get(
              settings,
              'documentTemplate.fieldsLabel.verificationNeeded',
              {
                default: 'Verification needed',
              }
            )}
          />
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            {isCreatePage
              ? get(settings, 'documentTemplate.submitButton.addTemplate', {
                  default: 'Add template',
                })
              : get(settings, 'documentTemplate.submitButton.updateTemplate', {
                  default: 'Update template',
                })}
          </Button>
        </div>
      </Container>
    </div>
  );
};
export default withSnackbar(TemplateForm);
