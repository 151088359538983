import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import get from 'get-value';
import moment from 'moment';

// importing material UI elements
import { Grid } from '@material-ui/core';

// importing internal components
import TextField from '../../../../components/forms/text-field';
import SelectField from '../../../../components/forms/select-field';
import DialogLayout from '../../../../components/dialogs/dialog-layout';

// importing constants
import { DOCUMENT_TYPE } from '../../../../utils/constants';
import { REGISTRY_ITEM_FIELDS } from '../../../../utils/form-constants';
import { DATE_FORMAT } from '../../../../helpers/date-helper';

// Mutation:
const createRegistryItemMutation = gql`
  mutation(
    $company: ID!
    $date: ISODate!
    $type: String!
    $description: String!
    $allocatedBy: String!
  ) {
    createRegistryItem(
      company: $company
      date: $date
      type: $type
      description: $description
      allocatedBy: $allocatedBy
    )
  }
`;

const updateRegistryItemMutation = gql`
  mutation(
    $registryItemId: ID!
    $date: ISODate!
    $allocatedBy: String!
    $description: String!
  ) {
    updateRegistryItem(
      registryItemId: $registryItemId
      date: $date
      description: $description
      allocatedBy: $allocatedBy
    )
  }
`;

const RegistryForm = ({
  settings,
  open,
  handleSubmitForm,
  setOpenDialog,
  handleCloseForm,
  initialFormInputs,
}) => {
  const [formInputs, setFormInputs] = useState(REGISTRY_ITEM_FIELDS);
  const [formInputErrors, setFormInputErrors] = useState(REGISTRY_ITEM_FIELDS);
  const [isCreateForm, setIsCreateForm] = useState(false);

  useEffect(() => {
    setIsCreateForm(Object.keys(initialFormInputs).length === 1);
    const newFormInputs = { ...formInputs };
    Object.entries(newFormInputs).map(([fieldName, fieldValue]) => {
      // Date fields (type: Date):
      if (
        Date.parse(initialFormInputs[fieldName]) &&
        isNaN(initialFormInputs[fieldName])
      ) {
        newFormInputs[fieldName] = moment(initialFormInputs[fieldName]).format(
          DATE_FORMAT
        );
      }
      // Simple fields (string):
      else {
        newFormInputs[fieldName] = initialFormInputs[fieldName] || fieldValue;
      }
    });
    setFormInputs(newFormInputs);
  }, [open]);

  const handleChange = (event, newValue) => {
    const name = get(event, 'target.name', { default: 'allocatedBy' });
    const value = get(event, 'target.value', { default: null }) || newValue;
    formInputErrors[name] !== '' &&
      setFormInputErrors({ ...formInputErrors, [name]: '' });
    setFormInputs({ ...formInputs, [name]: value });
  };

  // Mutation functions:
  const [createRegistryItem, {}] = useMutation(createRegistryItemMutation, {
    onCompleted: async () => {
      handleSubmitForm('successfullyCreatedRegistryItem');
      setFormInputs(REGISTRY_ITEM_FIELDS);
      setFormInputErrors(REGISTRY_ITEM_FIELDS);
      setOpenDialog(false);
    },
  });

  const handleCreateRegistryItem = () => {
    const { company, date, type, description, allocatedBy } = formInputs;
    createRegistryItem({
      variables: {
        company: company.id,
        date: moment.utc(date),
        type: type,
        description: description,
        allocatedBy: allocatedBy,
      },
    });
  };

  const [updateRegistryItem, {}] = useMutation(updateRegistryItemMutation, {
    onCompleted: async () => {
      handleSubmitForm('successfullyUpdatedRegistryItem');
      setFormInputs(REGISTRY_ITEM_FIELDS);
      setFormInputErrors(REGISTRY_ITEM_FIELDS);
      setOpenDialog(false);
    },
  });

  const handleUpdateRegistryItem = () => {
    const { date, allocatedBy, description } = formInputs;

    updateRegistryItem({
      variables: {
        registryItemId: initialFormInputs.id,
        date: moment.utc(date),
        allocatedBy: allocatedBy,
        description: description,
      },
    });
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;

    setFormInputErrors(REGISTRY_ITEM_FIELDS);

    Object.entries(formInputs).map(([name, value]) => {
      // Check if it has empty field(s) (don't check not required fields):
      if (!value && name !== 'number') {
        formIncomplete = true;
        setFormInputErrors((prevFormInputErrors) => {
          return {
            ...prevFormInputErrors,
            [name]: get(settings, 'formDialog.requiredInput', {
              default: 'Required!',
            }),
          };
        });
      }
    });

    return formIncomplete;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();

    if (!formIncomplete) {
      isCreateForm ? handleCreateRegistryItem() : handleUpdateRegistryItem();
    }
  };

  const handleClose = () => {
    setFormInputs(REGISTRY_ITEM_FIELDS);
    setFormInputErrors(REGISTRY_ITEM_FIELDS);
    handleCloseForm();
  };

  const documentTypeSelect = [
    {
      label: get(settings, 'registry.documentTypeSelectLabel.IN', {
        default: 'IN',
      }),
      value: DOCUMENT_TYPE.IN,
    },
    {
      label: get(settings, 'registry.documentTypeSelectLabel.OUT', {
        default: 'OUT',
      }),
      value: DOCUMENT_TYPE.OUT,
    },
  ];

  return (
    <DialogLayout
      formTitle={
        isCreateForm
          ? get(settings, 'registry.formDialog.addRegistryItem', {
              default: 'Add in-out ',
            })
          : get(settings, 'registry.formDialog.updateRegistryItem', {
              default: '',
            })
      }
      open={open}
      formSubmitButton={
        isCreateForm
          ? get(settings, 'registry.formDialog.addRegistryItemSubmitButton', {
              default: '',
            })
          : get(
              settings,
              'registry.formDialog.updateRegistryItemSubmitButton',
              {
                default: '',
              }
            )
      }
      formCancelButton={get(settings, 'formDialog.cancelButtonLabel', {
        default: '',
      })}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    >
      <Grid container spacing={2}>
        <SelectField
          name='company'
          value={formInputs.company}
          label={get(settings, 'registry.labels.company', {
            default: 'Company',
          })}
          variant='standard'
          error={formInputErrors.company}
          length={6}
          required={true}
          itemList={[formInputs.company]}
          itemNameList={['companyName']}
          handleChange={handleChange}
          disabled
        />
        <SelectField
          name='type'
          value={formInputs.type}
          label={get(settings, 'registry.labels.documentType', {
            default: 'Document type',
          })}
          variant='standard'
          error={formInputErrors.type}
          length={6}
          required={true}
          itemList={documentTypeSelect}
          itemNameList={['label']}
          menuItemValue='value'
          handleChange={handleChange}
          disabled={!isCreateForm}
        />
        {!isCreateForm && (
          <TextField
            type='text'
            margin='none'
            name='number'
            value={formInputs.number}
            label={get(settings, 'registry.labels.number', {
              default: 'Number',
            })}
            variant='standard'
            error={formInputErrors.number}
            length={6}
            handleChange={handleChange}
            disabled
          />
        )}
        <TextField
          type='date'
          margin='none'
          name='date'
          value={formInputs.date}
          label={get(settings, 'registry.labels.date', {
            default: 'Date',
          })}
          error={formInputErrors.date}
          InputLabelProps={{ shrink: true }}
          handleChange={handleChange}
        />
        <TextField
          type='text'
          margin='none'
          name='allocatedBy'
          length={6}
          value={formInputs.allocatedBy}
          label={get(settings, 'registry.labels.allocatedBy', {
            default: 'allocatedBy',
          })}
          error={formInputErrors.allocatedBy}
          handleChange={handleChange}
        />
        <TextField
          type='text'
          margin='none'
          name='description'
          length={12}
          value={formInputs.description}
          label={get(settings, 'registry.labels.description', {
            default: 'Description',
          })}
          error={formInputErrors.description}
          handleChange={handleChange}
        />
      </Grid>
    </DialogLayout>
  );
};

export default RegistryForm;
