import React from 'react';

// importing material UI elements
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
  Divider,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  progressContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const DialogLayout = (props) => {
  const {
    open = false,
    isLoading = false,
    handleClose = undefined,
    formTitle = 'form-title',
    handleSubmit = undefined,
    formSubmitButton = 'Submit',
    formCancelButton = 'Cancel',
    showSubmit = true,
    disabledSubmit = false,
    maxWidth = 'sm',
    uniformForm = false,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(maxWidth));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle id='form-dialog-title'>{formTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          props.children
        )}
      </DialogContent>
      {!uniformForm && (
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            {formCancelButton}
          </Button>
          {showSubmit && (
            <Button
              color='primary'
              onClick={handleSubmit}
              disabled={disabledSubmit}
            >
              {formSubmitButton}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogLayout;
