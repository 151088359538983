import React, { useState } from 'react';
import get from 'get-value';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

// importing Material UI core
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';

// importing Material UI icons
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

// importing internal components
import AttendanceSheet from './components/table-sheet';
import {
  DATE_FORMAT,
  momentDateFormat,
  calculateMonthAndYear,
} from '../../helpers/date-helper';
import Toolbar from '../../core/toolbar';
import AutocompleteField from './components/autocomplete-field';
import DateBrowser from './components/date-browser';
import CircularUnderLoad from '../../components/loading-animation';
import withSnackbar from '../../utils/with-snackbar';
import { useStore } from '../../stores';
import { getLanguageSupport } from '../../utils/helper';
import APIHelper from '../../helpers/route-helper';

const settings = getLanguageSupport();

const TEAM_FIELD = 'team';
const COMPANY_FIELD = 'company';
const FILTER_NAME = get(settings, 'attendancePage.filterPlaceHolder', {
  default: 'Companies and Teams',
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    padding: '20px 20px',
    marginTop: '-15px',
    fontSize: '20px',
    fontWeight: '700',
  },
  filterField: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
  overviewSheet: {
    paddingTop: 30,
  },
  checkBoxSpacing: {
    marginLeft: 20,
  },
}));

// GraphQL Queries & Operations
const readOverviewOutputQuery = gql`
  query (
    $monthAndYear: String!
    $filter: [filterObject]
    $displayInactive: Boolean
  ) {
    readOverviewOutput(
      monthAndYear: $monthAndYear
      filter: $filter
      displayInactive: $displayInactive
    ) {
      employeeID
      employeeName
      attendance {
        date
        status
        hours {
          stepIn
          stepOut
        }
      }
      totalHours
    }
  }
`;

const readAllTeamsQuery = gql`
  query ($filter: String) {
    readAllTeams(filter: $filter) {
      id
      teamName
    }
  }
`;

const readAllCompaniesQuery = gql`
  query {
    readAllCompanies {
      id
      companyName
    }
  }
`;

const DATE_NAVIGATION = {
  back: 0,
  next: 1,
};

const Attendance = () => {
  const classes = useStyles();

  const store = useStore();
  const { fullName, isAdmin } = store.employeeData;

  // States
  const [filterElements, setfilterElements] = useState([]);
  const [currentSheetMonth, setCurrentSheetMonth] = useState(
    momentDateFormat()
  );
  const currentMonthAndYear = calculateMonthAndYear(currentSheetMonth);
  const [isChecked, setIsChecked] = useState(false);

  let filterQuery = [
    { field: TEAM_FIELD, value: [], operator: 'equals' },
    { field: COMPANY_FIELD, value: [], operator: 'equals' },
  ];

  if (filterElements.length != 0) {
    filterElements.forEach((filterElement) => {
      if (filterElement.type === 'team') {
        filterQuery[0].value.push(filterElement.id);
      } else if (filterElement.type === 'company') {
        filterQuery[1].value.push(filterElement.id);
      }
    });
  }

  // GraphQL
  const {
    data: overviewData,
    refetch: refetchData,
    loading: loadingData,
  } = useQuery(readOverviewOutputQuery, {
    variables: {
      monthAndYear: currentMonthAndYear,
      displayInactive: isChecked,
      filter:
        filterQuery[0].value != 0 || filterQuery[1].value != 0
          ? filterQuery
          : [],
    },
  });

  const { data: teamsData } = useQuery(readAllTeamsQuery);

  const { data: companiesData } = useQuery(readAllCompaniesQuery);

  const handleChange = (event, values) => {
    setfilterElements(values);
  };

  const changeFieldValue = (event) => {
    setCurrentSheetMonth(event.target.value);
  };

  const handleClickGenerate = () => {
    APIHelper.prototype
      .fetchData(
        `/excel/generate`,
        'POST',
        {
          monthAndYear: currentMonthAndYear,
          displayInactive: isChecked,
          filter:
            filterQuery[0].value != 0 || filterQuery[1].value != 0
              ? filterQuery
              : [],
        },
        true
      )
      .then((response) => response.blob())
      .then((blob) => {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = currentMonthAndYear;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      });
  };

  const dateNavigate = (action) => {
    const currentSheetDate = currentSheetMonth;
    let newSheetDate;

    switch (action) {
      case DATE_NAVIGATION.back:
        newSheetDate = moment(currentSheetDate)
          .subtract(1, 'months')
          .format(DATE_FORMAT);
        break;
      case DATE_NAVIGATION.next:
        newSheetDate = moment(currentSheetDate)
          .add(1, 'months')
          .format(DATE_FORMAT);
        break;
      default:
        return;
    }
    setCurrentSheetMonth(newSheetDate);
  };

  const handleFilterCheck = () => {
    const currentCheck = isChecked;
    setIsChecked(currentCheck ? false : true);
  };

  if (!teamsData || !companiesData) {
    return <CircularUnderLoad />;
  }

  const filterData = [];

  teamsData.readAllTeams.forEach((teamElement) =>
    filterData.push({
      elementName: `${get(settings, 'attendancePage.team', {
        default: 'Team',
      })}: ${teamElement.teamName}`,
      id: teamElement.id,
      type: 'team',
    })
  );

  companiesData.readAllCompanies.forEach((companyElement) =>
    filterData.push({
      elementName: `${get(settings, 'attendancePage.company', {
        default: 'Company',
      })}: ${companyElement.companyName}`,
      id: companyElement.id,
      type: 'company',
    })
  );

  return (
    <div className='sectionContainer'>
      <Toolbar
        title={get(settings, 'attendancePage.title', { default: 'Attendance' })}
        buttons={[]}
      >
        <div className={classes.toolbar}>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <Grid item>
              <div className={classes.filterField}>
                <AutocompleteField
                  multiple={true}
                  filterData={filterData}
                  handleChange={handleChange}
                  filterElements={filterElements}
                  filterPlaceHolder={FILTER_NAME}
                />
              </div>
            </Grid>

            <Grid item>
              <>
                <DateBrowser
                  currentMonthAndYear={currentMonthAndYear}
                  changeFieldValue={changeFieldValue}
                  dateNavigate={dateNavigate}
                  dateNavigation={DATE_NAVIGATION}
                />
              </>
            </Grid>

            {isAdmin && (
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  endIcon={<DynamicFeedIcon />}
                  onClick={handleClickGenerate}
                >
                  {get(settings, 'attendance.excelGenerate', {
                    default: 'excelGenerate',
                  })}
                </Button>
              </Grid>
            )}

            {isAdmin && (
              <Tooltip
                title={get(settings, 'attendance.filterInactiveTip', {
                  default: 'filterInactiveTip',
                })}
              >
                <Grid item className={classes.checkBoxSpacing}>
                  <FormControlLabel
                    label={get(settings, 'attendance.filterInactive', {
                      default: 'filterInactive',
                    })}
                    control={
                      <Checkbox
                        checked={isChecked}
                        name='showInactive'
                        color='primary'
                        size='medium'
                        onChange={handleFilterCheck}
                      />
                    }
                  />
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </div>
      </Toolbar>
      <div className={classes.overviewSheet}>
        <AttendanceSheet
          settings={settings}
          userName={fullName}
          currentSheetDate={currentMonthAndYear}
          overviewData={overviewData}
          refetchData={refetchData}
          loadingData={loadingData}
          currentYear={currentMonthAndYear.split('-')[0]}
        />
      </div>
    </div>
  );
};

export default withSnackbar(Attendance);
