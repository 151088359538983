import React from 'react';
import get from 'get-value';
// importing material UI elements
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from '@material-ui/core';

// importing styles
import { useTheme } from '@material-ui/core/styles';

const ConfirmForm = (props) => {
  const {
    settings,
    handleClose = () => {},
    handleSubmit = () => {},
    disableSubmit = false,
    submitButtonLabel = get(
      settings,
      'confirmDialog.confirmRemoveButtonLabel',
      {
        default: 'Remove',
      }
    ),
    cancelButtonLabel = get(settings, 'confirmDialog.cancelButtonLabel', {
      default: 'Cancel',
    }),
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={props.open}
      maxWidth={'sm'}
      fullWidth={true}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle id='form-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          {cancelButtonLabel}
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={disableSubmit}>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmForm;
