import React from 'react';

// importing material UI elements
import {
  Grid,
  Typography,
  CardContent,
  Card,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '300px',
    borderRadius: '10px',
    marginTop: '1px',
    padding: '5px',
  },
  title: {
    marginTop: '20px',
    padding: '30px',
    textSize: '20px',
  },
  pos: {
    marginBottom: 12,
    marginLeft: 13,
  },
  marginTop: {
    marginTop: '45px',
  },
}));

const SecondCard = (props) => {
  const { title, children } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card className={classes.root} elevation={2}>
        <CardContent className={classes.marginTop}>
          {children}
          <Typography
            variant='h6'
            color='inherit'
            align='center'
            className={classes.title}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default SecondCard;
