import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import get from 'get-value';

// importing material UI components
import { Grid, TextField } from '@material-ui/core';

// importing internal components
import DialogLayout from '../../../components/dialogs/dialog-layout';
import { getLanguageSupport } from '../../../utils/helper';
import FreeDaysMap from './freedays-map';
import withSnackbar from '../../../utils/with-snackbar';
import { SNACKBAR_SEVERITY } from '../../../utils/constants';
import { dayRender } from '../../../helpers/date-helper';

// Queries
const readAllFreeDaysForYearQuery = gql`
  query readAllFreeDaysForYear($year: String!) {
    readAllFreeDaysForYear(year: $year) {
      year
      days
    }
  }
`;

// Mutations
const modifyFreeDaysMutation = gql`
  mutation createFreeDays($year: String!, $days: [String]!) {
    createFreeDays(year: $year, days: $days)
  }
`;

const settings = getLanguageSupport();

const CANCEL_BUTTON = get(settings, 'formDialog.cancelButtonLabel', {
  default: 'Cancel',
});

const SAVE_BUTTON = get(settings, 'formDialog.saveButtonLabel', {
  default: 'Save',
});

const FREE_DAYS = get(settings, 'attendancePage.freeDays', {
  default: 'Free Days',
});

const SELECT_YEAR = get(settings, 'attendancePage.selectYear', {
  default: 'Select year',
});

const FreeDaysDialog = (props) => {
  const { open, currentYear, handleClose } = props;

  const [freeDays, setFreeDays] = useState(null);
  const [initialDays, setInitialDays] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [readFreeDays] = useLazyQuery(readAllFreeDaysForYearQuery, {
    onCompleted: (data) => {
      if (data && data.readAllFreeDaysForYear) {
        // Format days from Date JS starts the months from 00 (January)
        const renderedDays = data.readAllFreeDaysForYear?.days?.map((dayItem) =>
          dayRender(dayItem)
        );
        setFreeDays(renderedDays || []);
        setInitialDays(renderedDays || []);
        setDataLoading(false);
      }
    },
  });

  const [modifyFreeDays, {}] = useMutation(modifyFreeDaysMutation, {
    onCompleted: () => {
      setSelectedYear(currentYear);
      handleClose();
      props.showSnackbar(
        get(settings, `attendancePage.successMessage`, {
          default: 'Edit successful. Please refresh!',
        }),
        SNACKBAR_SEVERITY.SUCCESS
      );
    },
  });

  useEffect(() => {
    if (open) {
      setDataLoading(true);
      readFreeDays({
        variables: {
          year: selectedYear,
        },
      });
    }
  }, [open, selectedYear]);

  const handleCloseDialog = () => {
    setFreeDays(initialDays);
    setSelectedYear(currentYear);
    handleClose();
  };

  const handleSubmit = () => {
    modifyFreeDays({
      variables: {
        year: selectedYear,
        days: freeDays,
      },
    });
  };

  return (
    <DialogLayout
      isLoading={dataLoading}
      open={open}
      formCancelButton={CANCEL_BUTTON}
      formSubmitButton={SAVE_BUTTON}
      handleClose={handleCloseDialog}
      formTitle={FREE_DAYS}
      handleSubmit={handleSubmit}
    >
      <Grid container justify='center' style={{ marginBottom: '10px' }}>
        <Grid item>
          <TextField
            type='number'
            label={SELECT_YEAR}
            value={selectedYear}
            onChange={(event) => {
              setSelectedYear(event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <FreeDaysMap freeDays={freeDays} setFreeDays={setFreeDays} />
    </DialogLayout>
  );
};

export default withSnackbar(FreeDaysDialog);
