import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Internal imports
import { StoreContext, storeBuilder } from './stores';
import './App.css';
import { logout } from './utils/authRoutes';
import Main from './layout/main';
import Routes from './layout/routes';
import APIHelper from './helpers/route-helper';

const store = storeBuilder();

const App = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const history = useHistory();

  useEffect(() => {
    APIHelper.prototype.fetchData(`/auth/token`, 'POST').then((res) => {
      if (res.status === 200) {
        setUserAuthenticated(true);
      } else {
        logout();
        const location = history.location.pathname;
        localStorage.setItem('lastLocation', location);
        history.push(`/login`);
      }
    });
  }, [history.location.pathname]);

  return (
    <StoreContext.Provider value={store}>
      <Routes userAuthenticated={userAuthenticated} />
      <Main userAuthenticated={userAuthenticated} />
    </StoreContext.Provider>
  );
};

export default App;
