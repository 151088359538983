import React from 'react';
import get from 'get-value';
import { envSettings } from '../../../config/config';

// importing material UI elements
import {
  Avatar,
  makeStyles,
  ListItemIcon,
  ListItem,
  Typography,
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

// importing Material UI icons
import { Edit, People, Delete, Business } from '@material-ui/icons';

// importing components
import CardLayout from '../../../core/card-layout';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    marginLeft: 'auto',
  },
  iconLink: {
    textDecoration: 'none',
  },
  detailsHeader: {
    fontWeight: 'bold',
  },
}));

const CardDisplay = (props) => {
  const { team, settings, handleClickEdit, handleClickRemove, isAdmin } = props;
  const classes = useStyles();

  const avatarGroup = (
    <AvatarGroup max={4}>
      {team.members.map((member, index) => {
        const avatarName = `${member.member.initials}`;
        return <Avatar key={`avatar-${index}`}>{avatarName}</Avatar>;
      })}
    </AvatarGroup>
  );

  const cardActions = [
    {
      name: 'edit',
      icon: <Edit fontSize='large' />,
      onClick: handleClickEdit,
      disabled: !isAdmin,
    },
    {
      name: 'members',
      icon: (
        <>
          <People fontSize='large' /> {team.totalMembers}
        </>
      ),
      linkTo: `/teams/${team.id}`,
      iconClassName: classes.iconLink,
    },
    {
      name: 'remove',
      icon: <Delete fontSize='large' />,
      onClick: handleClickRemove,
      iconClassName: classes.deleteIcon,
      disabled: !isAdmin || team.totalMembers !== 0,
    },
  ];

  const detailsHeader = (
    <ListItem dense>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <Typography className={classes.detailsHeader}>
        {get(settings, `teams.cardContainer.cardHeader`, {
          default: 'Card header',
        })}
      </Typography>
    </ListItem>
  );

  return (
    <CardLayout
      item={team}
      settings={settings}
      collection='teams'
      actions={cardActions}
      title={team.teamName}
      logoImageUrl={
        team.logo
          ? `${envSettings.BASE_URL}/minio/${team.logo}`
          : `${envSettings.BASE_URL}/minio/not-available.png`
      }
      detailsHeader={detailsHeader}
      details={
        team.members.length !== 0
          ? [
              { name: 'members', value: avatarGroup },
              { name: 'description', value: team.description },
            ]
          : [{ name: 'description', value: team.description }]
      }
    />
  );
};

export default CardDisplay;
