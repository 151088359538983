import React from 'react';

import { Grid, TextField as MaterialTextField } from '@material-ui/core';

const TextField = (props) => {
  const {
    settings,
    type = 'text',
    name = 'field-name',
    value = '',
    label = 'field-label',
    error = '',
    length = 6,
    required = true,
    handleChange = undefined,
    margin = 'dense',
    disabled = false,
    ...restProps
  } = props;

  return (
    <Grid item sm={length} xs={length}>
      <MaterialTextField
        fullWidth
        type={type}
        name={name}
        value={value}
        label={label}
        margin={margin}
        required={required}
        error={!!error}
        helperText={error && error}
        onChange={handleChange}
        disabled={disabled}
        {...restProps}
      />
    </Grid>
  );
};
export default TextField;
