import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';
import get from 'get-value';

// importing Material UI elements
import {
  Drawer,
  CssBaseline,
  Hidden,
  useTheme,
  makeStyles,
} from '@material-ui/core';

// importing internal components
import HeaderContent from './header';
import withSnackbar from '../utils/with-snackbar';
import Navigation from './navigation';

import { useStore } from '../stores';
import { getLanguageSupport } from '../utils/helper';

// importing constants
import { SNACKBAR_SEVERITY } from '../utils/constants';
import APIHelper from '../helpers/route-helper';

const drawerWidth = 240;
const appBarHeight = 45;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: appBarHeight,
  },
}));

const Main = observer((props) => {
  const { window, userAuthenticated } = props;

  const settings = getLanguageSupport();

  const store = useStore();
  const { employeeID, fullName, email } = store.employeeData;

  // STATE
  const [mobileOpen, setMobileOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    APIHelper.prototype
      .fetchData(`/auth/user-data`, 'POST')
      .then((response) => response.json())
      .then((data) => data && store.setEmployeeData(data))
      .catch(() => {
        props.showSnackbar(
          get(settings, 'access.loggedOut', {
            default: 'You are not logged in!',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
        history.push('/login');
      });
  }, []);

  function handleLoginButton() {
    APIHelper.prototype
      .fetchData(`/auth/logout`, 'GET')
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('lastLocation');
          if (localStorage.getItem('rememberMe') === 'false') {
            localStorage.removeItem('loginData');
          }
          history.push('/login');
          history.go();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (userAuthenticated && employeeID)
    return (
      <>
        <div className={classes.root}>
          <CssBaseline />
          <HeaderContent handleDrawerToggle={handleDrawerToggle} />
          <nav aria-label='mailbox folders'>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation='css'>
              <Drawer
                container={container}
                variant='temporary'
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <Navigation
                  settings={settings}
                  currentName={fullName}
                  currentEmail={email}
                  handleLoginButton={handleLoginButton}
                />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation='css'>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant='permanent'
                open
              >
                <Navigation
                  settings={settings}
                  currentName={fullName}
                  currentEmail={email}
                  handleLoginButton={handleLoginButton}
                />
              </Drawer>
            </Hidden>
          </nav>
        </div>
      </>
    );
  else return null;
});

export default withRouter(withSnackbar(Main));
