import get from 'get-value';
import { getLanguageSupport } from '../utils/helper';

const settings = getLanguageSupport();

export const checkIBAN = (param) => {
  const regExp = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
  const validIBAN = regExp.test(param);
  return validIBAN
    ? ''
    : get(
        settings,
        'companies.formDialog.fieldsLabel.representatives.errorIBAN',
        {
          default: 'errorIBAN',
        }
      );
};

export const checkSSN = (param) => {
  const regExp = /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/g;
  const validSSN = regExp.test(param);
  return validSSN
    ? ''
    : get(
        settings,
        'companies.formDialog.fieldsLabel.representatives.errorSSN',
        {
          default: 'errorSSN',
        }
      );
};
