//* Library imports
import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useStore } from '../../stores';
import moment from 'moment';
import get from 'get-value';

//* Material UI
import { makeStyles } from '@material-ui/core';

//* Custom imports
import Toolbar from '../../core/toolbar';
import CardDisplay from './components/card-display';
import CircularUnderLoad from '../../components/loading-animation';
import withSnackbar from '../../utils/with-snackbar';

//* importing constants
import { SNACKBAR_SEVERITY } from '../../utils/constants';
import EmploymentDataDisplay from './components/employment-data';

//*Queries
const readEmployeeInfoQuery = gql`
  query Employee($id: ID!) {
    readEmployee(id: $id) {
      id
      firstName
      lastName
      birthDate
      mobile
      remainingVacationDays
      userData {
        email
        isAdmin
      }
      employmentData {
        signature
        ssn
        identityCard {
          series
          number
        }
        vacationDays
        takenVacationDays
        dateOfEmployment
      }
    }
  }
`;

//* Mutations
const updateEmployeeMutation = gql`
  mutation(
    $id: ID!
    $firstName: String
    $lastName: String
    $birthDate: ISODate
    $mobile: String
  ) {
    updateEmployee(
      id: $id
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      mobile: $mobile
    )
  }
`;

const updateEmployeePasswordMutation = gql`
  mutation($id: ID!, $oldPassword: String!, $newPassword: String!) {
    updateEmployeePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'inline-flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
}));

const Account = (props) => {
  const { settings } = props;
  const store = useStore();

  const classes = useStyles();

  const { employeeID } = store.employeeData;

  const [employeeData, setEmployeeData] = useState({});
  const [edit, setEdit] = useState(false);

  //*Queries:
  const {
    loading: employeeInfoLoading,
    data: employeeInfoData,
    refetch: employeeInfoRefetch,
  } = useQuery(readEmployeeInfoQuery, {
    variables: { id: employeeID },
    skip: !employeeID,
    notifyOnNetworkStatusChange: true,

    onCompleted: (result) => {
      setEmployeeData({
        id: employeeID,
        firstName: result?.readEmployee?.firstName,
        lastName: result?.readEmployee?.lastName,
        mobile: result?.readEmployee?.mobile,
        remainingVacationDays: result?.readEmployee?.remainingVacationDays,
        birthDate: result?.readEmployee?.birthDate,
        email: result?.readEmployee?.userData?.email,
        signature: result?.readEmployee?.employmentData?.signature,
        ssn: result?.readEmployee?.employmentData?.ssn,
        series: result?.readEmployee?.employmentData?.identityCard?.series,
        number: result?.readEmployee?.employmentData?.identityCard?.number,
        dateOfEmployment:
          result?.readEmployee?.employmentData?.dateOfEmployment,
      });
    },
  });

  //*Mutations:
  const [updateEmployee, {}] = useMutation(updateEmployeeMutation, {
    onCompleted: () => {
      employeeInfoRefetch();
      showAccountSnackbar('updateEmployeeInfo');
      setEdit(!edit);
    },
  });

  const [updateEmployeePassword, {}] = useMutation(
    updateEmployeePasswordMutation,
    {
      onCompleted: (result) => {
        if (result.updateEmployeePassword === true) {
          showAccountSnackbar('updateEmployeePassword');
        }
      },
    }
  );

  const saveEmployeeChanges = (data) => {
    updateEmployee({
      variables: {
        ...data,
        id: employeeID,
        birthDate: moment.utc(data.birthDate),
      },
    });
  };

  const savePasswordChanges = (model) =>
    updateEmployeePassword({
      variables: {
        id: employeeID,
        oldPassword: model.oldPassword,
        newPassword: model.newPassword,
      },
    });

  const showAccountSnackbar = (
    message,
    severity = SNACKBAR_SEVERITY.SUCCESS
  ) => {
    props.showSnackbar(
      get(settings, `accountPage.snackbar.${message}`, { default: message }),
      severity
    );
  };

  if (employeeInfoLoading) {
    return (
      <div className='sectionContainer'>
        <Toolbar
          title={get(settings, 'accountPage.account.toolbarTitle', {
            default: 'Toolbar Title',
          })}
        />
        <CircularUnderLoad />
      </div>
    );
  }

  return (
    <div className='sectionContainer'>
      <Toolbar
        title={get(settings, 'accountPage.account.toolbarTitle', {
          default: 'toolbarTitle',
        })}
      />
      {employeeData && (
        <div className={classes.cards}>
          <CardDisplay
            title={get(settings, 'accountPage.account.accountTitle', {
              default: 'Account',
            })}
            employeeData={employeeData}
            setEmployeeData={setEmployeeData}
            saveEmployeeChanges={saveEmployeeChanges}
            edit={edit}
            setEdit={setEdit}
            savePasswordChanges={savePasswordChanges}
            settings={settings}
            employeeInfoRefetch={employeeInfoRefetch}
            showAccountSnackbar={showAccountSnackbar}
          />
          <EmploymentDataDisplay
            employeeData={employeeData}
            settings={settings}
          />
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Account);
