import React from 'react';

// importing material UI elements
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

const SelectField = (props) => {
  const {
    settings,
    type = 'text',
    name = 'field-name',
    value = '',
    label = 'field-label',
    error = '',
    length = 6,
    required = true,
    multiple = false,
    itemList = [],
    itemNameList = [],
    handleChange = undefined,
    className = '',
    menuItemValue = 'id',
    variant = 'outlined',
    ...restProps
  } = props;

  return (
    <Grid item sm={length} xs={12}>
      <FormControl
        fullWidth
        variant={variant}
        required={required}
        error={!!error}
        className={className}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          autoWidth
          name={name}
          multiple={multiple}
          value={value?.id ? value.id : value}
          label={label}
          onChange={(event) => {
            handleChange(event, event.target.value);
          }}
          {...restProps}
        >
          {itemList?.map((item, index) => (
            <MenuItem key={index} value={item[menuItemValue]}>
              {itemNameList &&
                itemNameList.map((itemName) => item[itemName]).join(' ')}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default SelectField;
