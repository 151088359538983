import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const schema = {
  title: 'Change password',
  type: 'object',
  properties: {
    oldPassword: {
      type: 'string',
      uniforms: { type: 'password' },
    },
    newPassword: {
      type: 'string',
      uniforms: { type: 'password' },
    },
    confirmNewPassword: {
      type: 'string',
      const: { $data: '1/newPassword' },
      uniforms: { type: 'password' },
    },
  },
  required: ['oldPassword', 'newPassword', 'confirmNewPassword'],
};

const createValidator = (schema) => {
  const validator = ajv.compile(schema);

  return (model) => {
    validator(model);
    return validator.errors?.length ? { details: validator.errors } : null;
  };
};

const schemaValidator = createValidator(schema);

export const bridge = new JSONSchemaBridge(schema, schemaValidator);
